import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import LinearProgress from "@material-ui/core/LinearProgress";

import { Row } from "react-data-grid";
import "react-splitter-layout/lib/index";

import { GridColumns } from "./producerstablesetupmain";

import { exportProducerIntakeAndShipments, ProducersFullGroupedOnlyType } from "../../../lib/api/producersfull";
import { DocumentsButton, DocumentsType } from "../../../lib/components/DocumentsButton";

import format from "date-fns/format";
import { getFinancialYearSelected } from "../../../lib/api/week";
import { SnackContext } from "../../../lib/context/SnackContext";
import { GenerateErrorMessage } from "../../../lib/helpers/string_methods";

import Grid from "../../../lib/components/grid";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
      width: "100%",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
      gap: `${theme.spacing(1)}px`,
    },
  });

type ProducersTableProps = {
  data: {}[];
  onReload: any;
} & WithStyles<typeof styles>;

class ProducersTableUnstyled extends React.Component<ProducersTableProps, {}> {
  state = {
    data: [{}],
    filters: {},
    sorting: {},
    onReload: undefined,
    selectedRow: undefined,
    loadingPrint: false,
  };

  constructor(props) {
    super(props);
    this.state.data = props.data;
    this.state.onReload = props.onReload;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  handleAvailableProducersAdvancesDoubleClick = (row: ProducersFullGroupedOnlyType) => {
    this.setState({ loadingDetail: true, selectedRows: [] });
    this.setState({ loadingDetail: false, selectedRow: { ...row } });
  };

  rowRenderer = (props) => {
    const { row } = props;
    return <Row {...props} onDoubleClickCapture={() => this.handleAvailableProducersAdvancesDoubleClick(row)} />;
  };

  handleExport = async (shouldIncludeShipments = true) => {
    this.setState({ loadingPrint: true });
    try {
      const filename = shouldIncludeShipments ? `${format(new Date(), "yyMMdd")}_ALL_INTAKES_AND_SHIPMENTS` : `${format(new Date(), "yyMMdd")}_ALL_INTAKES`;
      await exportProducerIntakeAndShipments("all", getFinancialYearSelected(), shouldIncludeShipments, filename);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error printing report");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
    this.setState({ loadingPrint: false });
  };

  menuItems = [
    {
      title: "ALL INTAKES",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExport(false),
    },
    {
      title: "ALL INTAKES AND SHIPMENTS",
      icon: DocumentsType.EXCEL,
      action: () => this.handleExport(),
    },
  ];

  render() {
    const { classes } = this.props;

    const totalRowColumns = [
      "bar_NoCartons",
      "bar_PalletSize",
      "paid_zar",
      "paid_zarcartons",
      "paid_cad",
      "paid_cadcartons",
      "paid_eur",
      "paid_eurcartons",
      "paid_gbp",
      "paid_gbpcartons",
      "paid_usd",
      "paid_usdcartons",
    ];

    return (
      <div className={classes.root}>
        {this.state.loadingPrint && <LinearProgress color="secondary" />}
        {this.state.selectedRow && <Redirect to={`/producers/grouped/${this.state.selectedRow.producer_id}`} />}
        <div className={classes.toolbarWrapper}>
          <Toolbar className={classes.toolbar}>
            <DocumentsButton menuItems={this.menuItems} disabled={(this.state.data || []).length === 0} />
          </Toolbar>
          <Grid
            rowRenderer={this.rowRenderer}
            clearFilters="producersfullgrouped"
            data={this.state.data}
            GridColumns={GridColumns}
            totalRowColumns={totalRowColumns}
            handleRefresh={this.props.onReload}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ProducersTableUnstyled);
