import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import { ContextMenu, MenuItem } from "react-contextmenu";
import { createPortal } from "react-dom";

import "../../lib/custom/splitter.css";
import "react-splitter-layout/lib/index";
import Splitter from "m-react-splitters";
import "m-react-splitters/lib/splitters.css";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Toolbar from "@material-ui/core/Toolbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Switch from "@material-ui/core/Switch";

import { Row } from "react-data-grid";
import classnames from "classnames";

import {
  ConsolidationGroupedType,
  getAllConsolidation,
  consoldationDetailByInvoice,
  ConsolidationDetail,
  getGroupedInvoices,
  getConsolidationDataQueueJobDetail,
  updateConsolidationQueue,
  bulkProcessConsolidationData,
  updateConsolidationDataStartJob,
  consolidationByInvoiceNumbers,
  clearConsolidationData,
  lockSelectedRows,
} from "../../lib/api/consolidation";
import { ConsolidationAmendType } from "../../lib/api/consolidationamend";
import { ConsolidationAmendDetailsType } from "../../lib/api/consolidationamenddetails";
import { GroupedGridColumns } from "./consolidationgroupedgridsetup";
import Grid from "../../lib/components/grid";
import { GreenButton, RedButton, OrangeButton, GreyButton } from "../../lib/components/ColorButtons";
import { SnackContext } from "../../lib/context/SnackContext";
import Confirmation from "../../lib/components/confirmation";
import { getFinancialYearSelected } from "../../lib/api/week";

import GroupedUnapprovedTable, { groupedFind } from "./groupedunapprovedtable";
import GroupedPendingTable from "./groupedpendingtable";
import InvoiceLinesInfoDialog from "../../invoicing/invoiceLinesInfoDialog";
import { BarcodeGridColumns } from "./consolidationbarcodegridsetup";
import ForeignCostInfo from "./foreigncostinfo/foreignCostInfo";

import ConsolidationEnqueueTimer from "./consolidationqueuetimer";
import { whoami } from "../../lib/api/_base";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { DocumentsButton, DocumentsType } from "../../lib/components/DocumentsButton";

import format from "date-fns/format";
import { detailedSeasonReportByInvoiceNumber, exportSelectedConsol } from "../../lib/helpers/Files";
import { DialogInformation } from "../../lib/components/dialoginformation";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "calc(100vh - 150px)",
      width: "100%",
      position: "relative",
    },
    gridContainer: {
      position: "relative",
      height: "100%",
    },
    splitter: {
      maxWidth: "calc(100vw - 150px)",
      gridGap: "10px",
    },
    totalRowStyle: {
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    totalRowStyleFixed: {
      position: "sticky",
      bottom: 0,
      background: "#ddd",
      textAlign: "right",
      fontWeight: "bold",
      color: "black",
    },
    lastRowStyle: {
      display: "flex",
      flexDirection: "column-reverse",
      color: "black",
    },
    inline: {
      display: "flex",
      alignContent: "center",
      position: "absolute",
      marginTop: "15px",
      marginLeft: "7px",
    },
    inlineRight: {
      marginLeft: "680px",
      marginTop: "13px",
      position: "absolute",
      zIndex: 99,
      fontWeight: "bolder",
      color: "orange",
    },
    redRow: {
      color: "red",
    },
    blackRow: {
      color: "black",
    },
    boldRow: {
      fontWeight: "bold",
      cursor: "pointer",
    },
    normalRow: {
      fontWeight: "normal",
      cursor: "pointer",
    },
    selectedRow: {
      backgroundColor: "lightgray",
    },
    toolbarWrapper: {
      position: "relative",
    },
    toolbar: {
      position: "absolute",
      left: 0,
      width: "100%",
    },
    tabpanel: {
      height: "100%",
      "& > :first-child": {
        height: "100%",
        padding: "5px",
        "& > :first-child": {
          height: "100%",
        },
      },
    },
    disabled: {
      color: "green",
    },
    contextMenu: {
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid rgba(0, 0, 0, 0.15)",
      borderRadius: "0.25rem",
      color: "#373a3c",
      fontSize: "16px",
      margin: "2px 0 0",
      minWidth: "160px",
      outline: "none",
      opacity: 0,
      padding: "5px 0",
      pointerEvents: "none",
      textAlign: "left",
      transition: "opacity 250ms ease !important",
    },
    contextMenuItem: {
      background: "0 0",
      border: 0,
      color: "#373a3c",
      cursor: "pointer",
      fontWeight: 400,
      lineHeight: 1.5,
      padding: "3px 20px",
      textAlign: "inherit",
      whiteSpace: "nowrap",
    },
  });

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export enum Status {
  Unapproved = 0,
  Pending = 1, // Waiting for approval
  Approved = 2,
  Processed = 3,
  Paid = 4,
}

let consolidationCurrentRow = undefined;

export type StateRowsType = {
  CountCode: string;
  FarmNumber: string;
  GradeCode: string;
  IntakeWaybill: string;
  InventoryCode: string;
  MarkCode: string;
  NoCartons: number;
  PackCode: string;
  TargetMarket: string;
  VarietyCode: string;
  adjustment: number;
  advance: number;
  arrivaldetail_qualityscore: string;
  ata: number;
  barcode: string;
  barcode_id: number;
  camend_final: number;
  camend_id: number;
  camend_status: number;
  camenddetails_commission: number;
  camenddetails_id: number;
  cga_est: number;
  cga_final: number;
  clients_dealtype: number;
  coldcost: number;
  coldcost_est: number;
  coldcost_final: number;
  comment: string;
  consignee: string;
  containerno: string;
  currency: string;
  exchangerate: number;
  final: number;
  fobcost: number;
  fobcost_est: number;
  fobcost_final: number;
  foreigncost: number;
  grossWeight: number;
  invoicenumber: string;
  ld_dip: string;
  ld_fob: string;
  ld_match: string;
  margin: number;
  marginperc: number;
  nettsellprice: number;
  palletSize: number;
  portdist_code: string;
  portfinal_code: string;
  ppecb_est: number;
  ppecb_final: number;
  prod_dealtype: string;
  prod_payment: string;
  producerid: string;
  roe_eta: Date;
  roe_etd: Date;
  royaltiesCost: number;
  royaltiesCost_est: number;
  royaltiesCost_final: number;
  rtgc: number;
  rtgczar: number;
  rtgp: number;
  rtgpzar: number;
  sale_credit: number;
  sale_credit_total: number;
  sale_debit: number;
  sale_debit_total: number;
  sati_est: number;
  sati_final: number;
  sellingprice: number;
  sellingprice_final: number;
  sellpricezar: number;
  target_shipping: string;
  totalcalcadjustment: number;
  totalcalccga_est: number;
  totalcalccga_final: number;
  totalcalccoldcost: number;
  totalcalccoldcost_est: number;
  totalcalccoldcost_final: number;
  totalcalcfobcost: number;
  totalcalcfobcost_est: number;
  totalcalcfobcost_final: number;
  totalcalcforeigncost: number;
  totalcalcmargin: number;
  totalcalcnettsellprice: number;
  totalcalcppecb_est: number;
  totalcalcppecb_final: number;
  totalcalcroyaltiesCost: number;
  totalcalcroyaltiesCost_est: number;
  totalcalcroyaltiesCost_final: number;
  totalcalcsati_est: number;
  totalcalcsati_final: number;
  totalcalcsellingprice: number;
  totalcalcsellingprice_final: number;
  totalcalcsellpricezar: number;
  totalcalctotalcost: number;
  totalcalctotalcostinclmargin: number;
  totalcalctransportCost: number;
  totalcalctransportCost_est: number;
  totalcalctransportCost_final: number;
  totalcost: number;
  totalcostinclmargin: number;
  transportCost: number;
  transportCost_est: number;
  transportCost_final: number;
  username: number;
  vessel_code: string;
  weight: number;
  wk_weekcold: string;
  rowDirty: boolean;
};

export interface GroupConsolidationAmendDataType extends ConsolidationAmendType {
  details: ConsolidationAmendDetailsType[];
}

export interface GroupConsolidationStatusDataType extends ConsolidationAmendType {
  details: {
    id: number;
    status: Status;
    barcode_id: number;
    invoicenumber: string;
  }[];
}

type ConsolidationFullProps = {
  currentUser: string;
} & WithStyles<typeof styles>;
export class ConsolidationUnstyled extends React.Component<ConsolidationFullProps, any> {
  state = {
    classes: undefined,
    groupedData: [],
    selectedData: [],
    selectedRows: [],
    loading: true,
    selectedDataLoading: false,
    detailLoading: true,
    mainGridHeight: 900,
    primaryGridSize: "40%",
    confirmEdit: false,
    tabValue: 0,
    consolidationDataDetail: [],
    consolidationDataDetailWaiting: [],
    consolidationDataDetailApproved: [],
    consolidationDataDetailProcessed: [],
    pendingSelectedData: [],
    approvedSelectedData: [],
    unapprovedSelectedRows: [],
    processedSelectedRows: [],
    processedFilteredRows: [],
    unapprovedFilteredRows: [],
    unnapprovedGrouped: false,
    unnapprovedGroupedData: [],
    pendingGrouped: false,
    pendingGroupedData: [],
    confirmClearAmendDetails: undefined,
    piDipFobInfo: { message: undefined, status: undefined, invoiceItem: undefined, open: false },
    foreignCostInfo: undefined,
    minimizeGrid: false,
    globalFilter: "",
    currentUser: "",
    dataQueueDetail: { run_timestamp: new Date().toDateString() },
    confirmUpdateQueue: false,
    printLoading: false,
    shouldRefresh: false,
    refreshTimerCount: false,
    canLockConsolidation: false,
    showLockedMessage: false,
  };

  constructor(props) {
    super(props);
    this.state.classes = props.classes;
  }

  static contextType = SnackContext;
  context!: React.ContextType<typeof SnackContext>;

  setGridHeight = () => {
    const el = document.getElementById("main_splitter_div");
    this.setState({ mainGridHeight: el.clientHeight - 130 });
  };

  loadContext = async () => {
    try {
      const user = await whoami();
      const dataQueueResp = await getConsolidationDataQueueJobDetail();

      this.setState({ currentUser: user.username, canLockConsolidation: user.consolidation_lock_permission, dataQueueDetail: (dataQueueResp || [{}])[0] });
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  componentDidMount() {
    this.loadContext();
    this.loadData().then(() => {
      this.setGridHeight();
    });
  }

  loadData = async () => {
    this.setState({ loading: true });

    await this.loadConsolidationData();

    await getGroupedInvoices(getFinancialYearSelected())
      .then((data) => {
        this.setState({ groupedData: data });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });

    this.setState({ loading: false });
  };

  loadConsolidationData = async () => {
    this.setState({ detailLoading: true });

    await getAllConsolidation()
      .then((data) => {
        this.setState({
          consolidationDataDetail: data,
          consolidationDataDetailWaiting: data.filter((item) => item.camend_status == Status.Pending),
          consolidationDataDetailApproved: data.filter((item) => item.camend_status == Status.Approved),
          consolidationDataDetailProcessed: data.filter((item) => item.camend_status == Status.Processed || item.camend_status == Status.Paid),
        });
      })
      .catch((error) => {
        const err = GenerateErrorMessage(error, "failed to retrieve data");
        this.context.updateSnack({ show: true, color: "red", message: err });
      });

    this.setState({ detailLoading: false });
  };

  handleLoadSelectedRow = async (row: string | string[]) => {
    this.setState({ selectedDataLoading: true });
    try {
      const result: ConsolidationDetail[] = await consoldationDetailByInvoice(row);
      this.setState({ selectedData: [...this.state.selectedData, ...result] });
    } catch (error) {
      const err = GenerateErrorMessage(error, "failed to retrieve data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    this.setState({ selectedDataLoading: false });
  };

  handleRowClick = async (rowData) => {
    const exists = this.state.selectedRows.find((selected) => selected.invoicenumber == rowData.invoicenumber);

    if (exists) {
      const filteredRows = this.state.selectedRows.filter((sr: ConsolidationDetail) => sr.invoicenumber != rowData.invoicenumber);
      const filteredData = this.state.selectedData.filter((sr: ConsolidationDetail) => sr.invoicenumber != rowData.invoicenumber);

      this.setState({ selectedRows: filteredRows, selectedData: filteredData });
    } else {
      this.setState({ selectedRows: [...this.state.selectedRows, rowData] });
      this.handleLoadSelectedRow([rowData.invoicenumber]);
    }
  };

  handleGroupedRefresh = () => {
    this.loadData();
  };

  handleDetailRefresh = () => {
    this.loadConsolidationData();
  };

  handleProcess = async (row: ConsolidationGroupedType) => {
    this.setState({ loading: true });
    this.setState({ confirmEdit: false });
    this.loadData();
  };

  handleRowsUpdated = (column: string, rows: ConsolidationDetail[]) => {
    const selectedRows = this.state.unapprovedSelectedRows;

    const newData = this.state.selectedData.map((item: ConsolidationDetail) => {
      const newRow = rows.find((findRow) => item.barcode_id == findRow.barcode_id);
      if (newRow) {
        if (newRow.rowDirty) {
          selectedRows.push(newRow);
        }
        return newRow;
      }
      return item;
    });

    this.setState({ selectedData: newData, unapprovedSelectedRows: selectedRows });
  };

  handleRowsUpdatedPending = (column: string, rows: ConsolidationDetail[]) => {
    const selectedRows = this.state.pendingSelectedData;

    const updated = this.state.consolidationDataDetailWaiting.map((item) => {
      const exists = rows.find((row) => row.barcode_id == item.barcode_id);
      if (exists) {
        if (exists.rowDirty) {
          selectedRows.push(exists);
        }
        return exists;
      }
      return item;
    }, []);

    this.setState({ consolidationDataDetailWaiting: updated, pendingSelectedData: selectedRows });
  };

  handleClearWaitingSelected = async () => {
    this.setState({ pendingSelectedData: [] });
  };

  handleClearApprovedSelected = async () => {
    this.setState({ approvedSelectedData: [] });
  };

  rowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.selectedRows && this.state.selectedRows.find((selectedRow) => selectedRow.invoicenumber == row.invoicenumber) && classes.boldRow;
    const selected = consolidationCurrentRow && consolidationCurrentRow.barcode_id == row.barcode_id ? classes.selectedRow : classes.normalRow;

    const doubleClick = () => {
      this.handleRowClick(row);
    };

    return <Row {...props} className={classnames(fontWeight, selected)} onDoubleClickCapture={doubleClick} />;
  };

  approvedRowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.approvedSelectedData.find((p: ConsolidationDetail) => p.barcode_id == row.barcode_id) ? classes.boldRow : classes.normalRow;
    // const selected = consolidationCurrentRow && consolidationCurrentRow.barcode_id == row.barcode_id ? classes.selectedRow : classes.normalRow;

    return <Row {...props} className={classnames(fontWeight)} onDoubleClickCapture={() => this.handleApprovedRowDoubleClick(row)} />;
  };

  processedRowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.processedSelectedRows.find((p) => p.barcode_id == row.barcode_id) && classes.boldRow;
    const disabled = row.camend_status == Status.Paid && classes.disabled;
    // const selected = consolidationCurrentRow && consolidationCurrentRow.barcode_id == row.barcode_id ? classes.selectedRow : classes.normalRow;

    const handleClick = () => {
      if (row.camend_status != Status.Paid) {
        this.selectProcessedRow(row);
      }
    };

    return <Row {...props} className={classnames(fontWeight, disabled)} onDoubleClickCapture={handleClick} />;
  };

  pendingRowRenderer = (props, classes) => {
    const { row } = props;
    const fontWeight = this.state.pendingSelectedData.find((p) => p.barcode_id == row.barcode_id) ? classes.boldRow : classes.normalRow;
    const colorStyle = row.rowDirty == 1 && row.camend_status != Status.Processed ? classes.redRow : classes.blackRow;
    // const selected = consolidationCurrentRow && consolidationCurrentRow.barcode_id == row.barcode_id ? classes.selectedRow : classes.normalRow;

    return <Row {...props} className={classnames(fontWeight, colorStyle)} onDoubleClickCapture={() => this.handlePendingRowDoubleClick(row)} />;
  };

  unapprovedRowRenderer = (props, classes) => {
    const { row } = props;

    const fontWeight = this.state.unapprovedSelectedRows.find((p) => p.barcode_id == row.barcode_id) ? classes.boldRow : classes.normalRow;
    const colorStyle = row.rowDirty == 1 && row.camend_status != Status.Processed ? classes.redRow : classes.blackRow;
    // const selected = consolidationCurrentRow && consolidationCurrentRow.barcode_id == row.barcode_id ? classes.selectedRow : classes.normalRow;
    // const disabled = row.camend_status == Status.Paid ? classes.disabled : classes.normalRow;

    const doubleClick = () => {
      if (row.camend_status == Status.Unapproved) {
        this.selectUnapprovedRow(row);
      }
    };

    return <Row {...props} className={classnames(fontWeight, colorStyle)} onDoubleClickCapture={doubleClick} />;
  };

  handlePendingRowDoubleClick = (row) => {
    const exists = this.state.pendingSelectedData.find((pRow) => pRow.barcode_id == row.barcode_id);

    if (exists) {
      const result = this.state.pendingSelectedData.filter((pRow) => pRow.barcode_id != row.barcode_id);
      this.setState({ pendingSelectedData: result });
    } else {
      this.setState({ pendingSelectedData: [...this.state.pendingSelectedData, { ...row, rowDirty: 1 }] });
    }
  };

  handleApprovedRowDoubleClick = (row) => {
    const exists = this.state.approvedSelectedData.find((pRow) => pRow.barcode_id == row.barcode_id);

    if (exists) {
      const result = this.state.approvedSelectedData.filter((pRow) => pRow.barcode_id != row.barcode_id);
      this.setState({ approvedSelectedData: result });
    } else {
      this.setState({ approvedSelectedData: [...this.state.approvedSelectedData, { ...row, rowDirty: 1 }] });
    }
  };

  handleProcessedRowDoubleClick = (row) => {
    const exists = this.state.processedSelectedRows.find((pRow) => pRow.barcode_id == row.barcode_id);

    if (exists) {
      const result = this.state.processedSelectedRows.filter((pRow) => pRow.barcode_id != row.barcode_id);
      this.setState({ processedSelectedRows: result });
    } else {
      this.setState({ processedSelectedRows: [...this.state.processedSelectedRows, { ...row, rowDirty: 1 }] });
    }
  };

  groupedFilteredRows = [];
  handleGroupedFilteredRows = (rows: any[]) => {
    this.groupedFilteredRows = rows;
  };

  pendingFilteredRows;
  handlePendingFilteredRows = (rows: any[]) => {
    this.pendingFilteredRows = rows;
  };

  approvedFilteredRows;
  handleApprovedFilteredRows = (rows: any[]) => {
    this.approvedFilteredRows = rows;
  };

  processedFilteredRows;
  handleProcessedFilteredRows = (rows: any[]) => {
    this.processedFilteredRows = rows;
  };

  unapprovedFilteredRows;
  handleUnapprovedFilteredRows = (rows: any[]) => {
    this.unapprovedFilteredRows = rows;
  };

  handleSelectAllPendingRows = () => {
    this.setState({ pendingSelectedData: this.pendingFilteredRows.map((item) => ({ ...item, rowDirty: 1 })) });
  };

  handleSelectAllApprovedRows = () => {
    this.setState({ approvedSelectedData: this.approvedFilteredRows });
  };

  selectAllProcessedData = () => {
    this.setState({ processedSelectedRows: this.processedFilteredRows.filter((item) => item.camend_status == Status.Processed) });
  };

  clearAllProcessedSelectedRows = () => {
    this.setState({ processedSelectedRows: [] });
  };

  selectProcessedRow = (row) => {
    const exists = this.state.processedSelectedRows.find((item) => item.barcode_id == row.barcode_id);
    if (exists) {
      const filtered = this.state.processedSelectedRows.filter((item) => item.barcode_id != row.barcode_id);
      this.setState({ processedSelectedRows: filtered });
    } else {
      this.setState({ processedSelectedRows: [...this.state.processedSelectedRows, { ...row, rowDirty: 1 }] });
    }
  };

  handleUnapprovedRefresh = async () => {
    this.setState({ selectedDataLoading: true });
    if (this.state.selectedRows.length > 0) {
      const invoiceNumbersList = this.state.selectedRows.map((item) => item.invoicenumber);
      const selectedData = await consolidationByInvoiceNumbers(invoiceNumbersList);

      this.setState({ selectedData });
    } else {
      this.setState({ selectedData: [] });
    }

    this.setState({ selectedDataLoading: false });
  };

  clearUnapprovedSelectedRows = () => {
    this.setState({ unapprovedSelectedRows: [] });
  };

  selectAllUnapproved = () => {
    const unapprovedSelectedRows = this.unapprovedFilteredRows.filter((item) => item.camend_status == Status.Unapproved).map((item) => ({ ...item, rowDirty: 1 }));
    this.setState({ unapprovedSelectedRows });
  };

  selectAllGrouped = () => {
    const invoiceNumbersList = (this.groupedFilteredRows || []).map((item) => item.invoicenumber);
    this.handleLoadSelectedRow(invoiceNumbersList);
    this.setState({ selectedRows: this.groupedFilteredRows });
  };

  selectUnapprovedRow = (row) => {
    const exists = this.state.unapprovedSelectedRows.find((item) => item.barcode_id == row.barcode_id);
    if (exists) {
      const filtered = this.state.unapprovedSelectedRows.filter((item) => item.barcode_id != row.barcode_id);
      this.setState({ unapprovedSelectedRows: filtered });
    } else {
      this.setState({ unapprovedSelectedRows: [...this.state.unapprovedSelectedRows, { ...row, rowDirty: 1 }] });
    }
  };

  selectUnpprovedRowGrouped = (rows) => {
    this.setState({ unapprovedSelectedRows: rows });
  };

  selectPendingRowGrouped = (rows) => {
    this.setState({ pendingSelectedData: rows });
  };

  handleGroupData = () => {
    this.setState({ unnapprovedGrouped: !this.state.unnapprovedGrouped });
  };

  handleUpdateSelectedData = (updated) => {
    this.setState({ selectedData: updated }); // , unapprovedSelectedRows: updated
  };

  handleGroupUpdateSelectedData = (updated) => {
    this.setState({ consolidationDataDetailWaiting: updated });
  };

  handleChangePendingDataGroup = () => {
    this.setState({ pendingGrouped: !this.state.pendingGrouped });
  };

  handlePendingGroupRowsSelected = (rows) => {
    const filtered = this.state.consolidationDataDetailWaiting.filter((item) => rows.find((row) => groupedFind(item, { ...row, rowDirty: 1 })));
    this.setState({ pendingSelectedData: [...filtered] });
  };

  handleClearAmendDetails = (row) => {
    this.setState({ confirmClearAmendDetails: row });
  };

  handleClearAmendDetailsConfirm = async (confirm) => {
    this.setState({ loading: true });
    try {
      if (confirm) {
        await clearConsolidationData(this.state.confirmClearAmendDetails);
        this.setState({ confirmClearAmendDetails: undefined });
      }
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error removing Consolidation Data.");
      this.context.updateSnack({ show: true, color: "red", message: err });
      this.setState({ loading: false });
    }

    this.setState({ selectedData: [], selectedRows: [], confirmClearAmendDetails: undefined });
    this.loadData();
  };

  handleClearGroupSelected = () => {
    this.setState({ selectedRows: [], selectedData: [], unapprovedSelectedRows: [] });
  };

  handleApplyCellValue = (data, applyAll) => {
    const { row, column } = data;
    const selectedData: ConsolidationDetail[] = [...this.state.selectedData];
    const currentFindIndex = applyAll ? 0 : selectedData.findIndex((item) => item.barcode_id == row.barcode_id);
    const loopIndex = applyAll ? selectedData.length : currentFindIndex + 1;

    for (let index = currentFindIndex; index < loopIndex; index++) {
      const element = selectedData[index];
      const checkFilteredForUpdate = this.unapprovedFilteredRows.findIndex((uaitem) => uaitem.barcode_id == element.barcode_id);
      if (checkFilteredForUpdate >= 0) {
        switch (true) {
          case column.includes("fobcost"):
            selectedData[index] = { ...element, fobcost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("coldcost"):
            selectedData[index] = { ...element, coldcost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("ppecb_est"):
            selectedData[index] = { ...element, ppecb_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("cga_est"):
            selectedData[index] = { ...element, cga_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("royaltiesCost_est"):
            selectedData[index] = { ...element, royaltiesCost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("transportCost"):
            selectedData[index] = { ...element, transportCost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("transportCost_est"):
            selectedData[index] = { ...element, transportCost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("sati_est"):
            selectedData[index] = { ...element, sati_final: element[column] || 0, rowDirty: 1 };
            break;
          default:
            break;
        }
      }
    }

    this.setState({ selectedData });
  };

  handleApplyCellValueWaiting = (data, applyAll) => {
    const { row, column } = data;
    const consolidationDataDetailWaiting = [...this.state.consolidationDataDetailWaiting];
    const currentFindIndex = applyAll ? 0 : consolidationDataDetailWaiting.findIndex((item) => item.barcode_id == row.barcode_id);
    const loopIndex = applyAll ? consolidationDataDetailWaiting.length : currentFindIndex + 1;

    for (let index = currentFindIndex; index < loopIndex; index++) {
      const element = consolidationDataDetailWaiting[index];
      const checkFilteredForUpdate = this.pendingFilteredRows.findIndex((uaitem) => uaitem.barcode_id == element.barcode_id);
      if (checkFilteredForUpdate >= 0) {
        switch (true) {
          case column.includes("fobcost"):
            consolidationDataDetailWaiting[index] = { ...element, fobcost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("coldcost"):
            consolidationDataDetailWaiting[index] = { ...element, coldcost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("ppecb_est"):
            consolidationDataDetailWaiting[index] = { ...element, ppecb_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("cga_est"):
            consolidationDataDetailWaiting[index] = { ...element, cga_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("royaltiesCost_est"):
            consolidationDataDetailWaiting[index] = { ...element, royaltiesCost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("transportCost"):
            consolidationDataDetailWaiting[index] = { ...element, transportCost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("transportCost_est"):
            consolidationDataDetailWaiting[index] = { ...element, transportCost_final: element[column] || 0, rowDirty: 1 };
            break;
          case column.includes("sati_est"):
            consolidationDataDetailWaiting[index] = { ...element, sati_final: element[column] || 0, rowDirty: 1 };
            break;
          default:
            break;
        }
      }
    }

    this.setState({ consolidationDataDetailWaiting });
  };

  handlePIInfo = (row, close: Boolean) => {
    if (!close) {
      const message = "FOB Price - " + row.ld_fob + "\n" + "DIP Price - " + row.ld_dip + "\n" + row.ld_match.substring(3);
      const status = row.ld_match[0];
      const invoiceItem = row;
      this.setState({ piDipFobInfo: { message, status, invoiceItem, open: true } });
    } else {
      this.setState({ piDipFobInfo: { message: undefined, status: undefined, invoiceItem: undefined, open: false } });
    }
  };

  handleSelectedRecordsProcess = async (arrayData, newStatus: Status) => {
    this.setState({ loading: true, selectedDataLoading: true, detailLoading: true });

    // if any of the pallets are locked, user is unable to make changes to it.
    if (arrayData.find((item) => item.locked)) {
      this.handleToggleLockedWarning(true);
      this.setState({ loading: false, selectedDataLoading: false, detailLoading: false });
      return;
    }

    try {
      const stateRows: StateRowsType[] = arrayData;

      const camendData = stateRows.reduce((camendData: { [index: string]: GroupConsolidationAmendDataType }, row) => {
        if (!row.rowDirty && !newStatus) return camendData;

        camendData[row.invoicenumber] = {
          id: row.camend_id,
          invoicenumber: row.invoicenumber,
          username: this.state.currentUser,
          details: [
            ...(camendData[row.invoicenumber] || { details: [] }).details,
            {
              id: row.camenddetails_id,
              consolidationamend_id: row.camend_id,
              barcode_id: row.barcode_id,
              roe: row.exchangerate,
              fob: row.fobcost_final,
              cold: row.coldcost_final,
              commission: row.camenddetails_commission,
              adjustment: row.adjustment,
              status: newStatus != undefined ? newStatus : row.camend_status,
              final: row.final,
              comment: row.comment,
              ppecb: row.ppecb_final,
              cga: row.cga_final,
              sati: row.sati_final,
              transport: row.transportCost_final,
              royalties: row.royaltiesCost_final,
              invoicenumber: row.invoicenumber,
              created_at: new Date(),
              updated_at: new Date(),
            },
          ],
        };

        return camendData;
      }, {});

      await bulkProcessConsolidationData(Object.values(camendData));
      this.context.updateSnack({ show: true, color: "info", message: "Processing has started" });
      // await addConsolidationDataStartJob();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating amend data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    try {
      await updateConsolidationDataStartJob();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Job is already started");
      this.context.updateSnack({ show: true, color: "orange", message: err });
    }

    this.setState({
      confirmEdit: false,
      pendingSelectedData: [],
      approvedSelectedData: [],
      processedSelectedRows: [],
      loading: false,
      selectedDataLoading: false,
      detailLoading: false,
    });
    this.toggleRefreshTimerCount();
  };

  /* unapproved */
  handleProcessUnapproved = async (status: Status) => {
    this.setState({ loading: true, selectedDataLoading: true, detailLoading: true });

    try {
      const stateRows: StateRowsType[] = status == Status.Unapproved ? this.state.selectedData : this.state.unapprovedSelectedRows;

      const camendData = stateRows.reduce((camendData: { [index: string]: GroupConsolidationAmendDataType }, row) => {
        if (!row.rowDirty && status == Status.Unapproved) return camendData;

        camendData[row.invoicenumber] = {
          id: row.camend_id,
          invoicenumber: row.invoicenumber,
          username: this.state.currentUser,
          details: [
            ...(camendData[row.invoicenumber] || { details: [] }).details,
            {
              id: row.camenddetails_id,
              consolidationamend_id: row.camend_id,
              barcode_id: row.barcode_id,
              roe: row.exchangerate,
              fob: row.fobcost_final,
              cold: row.coldcost_final,
              ppecb: row.ppecb_final,
              cga: row.cga_final,
              adjustment: row.adjustment,
              commission: row.camenddetails_commission,
              status: status,
              final: row.final,
              comment: row.comment,
              sati: row.sati_final,
              transport: row.transportCost_final,
              royalties: row.royaltiesCost_final,
              invoicenumber: row.invoicenumber,
              created_at: new Date(),
              updated_at: new Date(),
            },
          ],
        };

        return camendData;
      }, {});

      await bulkProcessConsolidationData(Object.values(camendData));
      this.context.updateSnack({ show: true, color: "info", message: "Processing has started" });
      // await addConsolidationDataStartJob();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Error creating amend data");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }

    try {
      await updateConsolidationDataStartJob();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Job is already started");
      this.context.updateSnack({ show: true, color: "orange", message: err });
    }

    this.setState({ loading: false, selectedDataLoading: false, detailLoading: false }, () => {
      this.handleClearGroupSelected();
      this.toggleRefreshTimerCount();
    });
  };

  // handleStatusChange = async (selectedRows: StateRowsType[], status: Status) => {
  //   try {
  //     const camendData = selectedRows.reduce((camendData: { [index: string]: GroupConsolidationStatusDataType }, row) => {
  //       camendData[row.invoicenumber] = {
  //         id: row.camend_id,
  //         invoicenumber: row.invoicenumber,
  //         username: this.state.currentUser,
  //         details: [...((camendData[row.invoicenumber] || { details: [] }).details), {
  //           id: row.camenddetails_id,
  //           status: status,
  //           barcode_id: row.barcode_id,
  //           invoicenumber: row.invoicenumber,
  //         }],
  //       };

  //       return camendData
  //     }, {});

  //     console.log("camendData", camendData);

  //     await bulkUpdateConsolidationStatus(Object.values(camendData));

  //   } catch (error) {
  //     const err = GenerateErrorMessage(error, "Error updating consolidation status");
  //     this.context.updateSnack({ show: true, color: "red", message: err });
  //   };

  //   this.setState({ loading: false, selectedDataLoading: false, detailLoading: false, confirmEdit: false, pendingSelectedData: [], approvedSelectedData: [], processedSelectedRows: [] }, () => {
  //     this.handleClearGroupSelected();
  //     this.handleGroupedRefresh();
  //   });
  // }

  handleGridBarcodeSelectedRowChange = (selectedRow: any, columns: { selectedColumn: number; columnsLength: number }) => {
    consolidationCurrentRow = selectedRow;
  };

  handleForeignCostInfo = (row) => {
    this.setState({ foreignCostInfo: row });
  };

  handleToggleGridMinimize = () => {
    this.setState({ minimizeGrid: !this.state.minimizeGrid });
  };

  handlePrint = async (includeProducer: boolean) => {
    this.setState({ printLoading: true });
    const invoicenumbers = this.state.selectedRows.map((item) => item.invoicenumber);
    await detailedSeasonReportByInvoiceNumber(invoicenumbers, includeProducer, getFinancialYearSelected());
    this.setState({ printLoading: false });
  };

  getLastDate = () => {
    if (this.state.dataQueueDetail && this.state.dataQueueDetail.run_timestamp) {
      return format(new Date(this.state.dataQueueDetail.run_timestamp.replace("Z", "")), "dd MMM yyyy pp");
    } else {
      ("N/A");
    }
  };

  menuItems = [
    {
      icon: DocumentsType.EXCEL,
      title: "DETAILED SEASON REPORT",
      action: () => this.handlePrint(false),
    },
    {
      icon: DocumentsType.EXCEL,
      title: "DETAILED SEASON REPORT - PRODUCER",
      action: () => this.handlePrint(true),
    },
  ];

  handleUpdateConsolidationQueue = async () => {
    try {
      await updateConsolidationQueue();
      this.handleCloseQueueDialog();
      this.context.updateSnack({ show: true, color: "green", message: "Update queue in progress" });
    } catch (error) {
      this.handleCloseQueueDialog();
      const err = GenerateErrorMessage(error, "Error starting queue process");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleCloseQueueDialog = () => {
    this.setState({ confirmUpdateQueue: false });
  };

  handleOpenQueueDialog = () => {
    this.setState({ confirmUpdateQueue: true });
  };

  handleExport = async () => {
    const invoicenumbers = this.state.selectedRows.map((item) => item.invoicenumber);
    if (invoicenumbers.length > 0) {
      this.setState({ selectedDataLoading: true });
      await exportSelectedConsol(invoicenumbers);
      this.setState({ selectedDataLoading: false });
    }
  };

  toggleRefreshTimer = (value: boolean) => {
    this.setState({ shouldRefresh: value });
  };

  toggleRefreshTimerCount = () => {
    this.setState({ refreshTimerCount: !this.state.refreshTimerCount });
  };

  handleLockSelectedRows = async () => {
    try {
      const selected = this.state.processedSelectedRows.map((row) => ({ barcode_id: row.barcode_id, locked: row.locked ? 0 : 1 }));
      await lockSelectedRows(selected);
      this.setState({ processedSelectedRows: [] });
      this.context.updateSnack({ show: true, color: "green", message: "Success. Lock status updated" });
      await this.loadConsolidationData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed. Please try again");
      this.context.updateSnack({ show: true, color: "red", message: err });
    }
  };

  handleToggleLockedWarning = (open: boolean) => {
    this.setState({ showLockedMessage: open });
  };

  render() {
    const { classes } = this.state;

    const totalRows = [
      "NoCartons",
      "palletSize",
      "sellingprice",
      "sellingprice_final",
      "foreigncost",
      "sale_credit_total",
      "sale_debit_total",
      "totalcalcsellingprice",
      "totalcalcsellingprice_final",
      "totalcalcforeigncost",
      "totalcalcadjustment",
      "totalcalcnettsellprice",
      "totalcalcsellpricezar",
      "totalcalcfobcost",
      "totalcalcfobcost_est",
      "totalcalcfobcost_final",
      "totalcalccoldcost",
      "totalcalccoldcost_est",
      "totalcalccoldcost_final",
      "totalcalcppecb_est",
      "totalcalcppecb_final",
      "totalcalccga_est",
      "totalcalccga_final",
      "totalcalcsellpricezar",
      "totalcalcsellpricezar",
      "totalcalcsellpricezar",
      "totalcalcmargin",
      "totalcalctotalcost",
      "nettsellprice",
      "roe_eta",
      "roe_etd",
      "exchangerate",
      "sellpricezar",
      "fobcost",
      "fobcost_est",
      "fobcost_final",
      "coldcost",
      "coldcost_est",
      "coldcost_final",
      "margin",
      "totalcost",
      "rtgc",
      "rtgp",
      "rtgpzar",
      "advance",
      "final",
      "ld_fob",
      "ld_dip",
      "totalcalctotalcostinclmargin",
      "totalcalcroyaltiesCost_final",
      "totalcalcroyaltiesCost_est",
      "totalcalctransportCost",
      "totalcalctransportCost_est",
      "totalcalctransportCost_final",
    ];

    return (
      <div className={classes.root}>
        {this.state.showLockedMessage && (
          <DialogInformation
            isOpen
            showinput={false}
            handleOK={() => this.handleToggleLockedWarning(false)}
            handleClose={() => this.handleToggleLockedWarning(false)}
            title="Locked pallets found."
            body="Pallets locked. Unlock to update."
          />
        )}
        {this.state.printLoading && <LinearProgress color="secondary" />}
        {this.state.confirmUpdateQueue && (
          <Confirmation
            title={"Confirm start queue"}
            isOpen={true}
            handleClose={this.handleCloseQueueDialog}
            handleConfirm={this.handleUpdateConsolidationQueue}
            body={`This process could take up to 30 minutes. Are you sure you want to continue?`}
          />
        )}
        {this.state.confirmClearAmendDetails && (
          <Confirmation
            title={"Confirm Clear"}
            isOpen={true}
            handleClose={() => this.handleClearAmendDetailsConfirm(false)}
            handleConfirm={() => this.handleClearAmendDetailsConfirm(true)}
            body={`This will clear all entered data for shipment`}
          />
        )}
        {this.state.piDipFobInfo.open && (
          <Confirmation
            title={"PI Info"}
            isOpen={true}
            handleClose={() => this.handlePIInfo(undefined, true)}
            handleConfirm={() => this.handlePIInfo(undefined, true)}
            body={undefined}
          >
            <InvoiceLinesInfoDialog
              key={`invoicelinesinfodialog`}
              message={this.state.piDipFobInfo.message}
              status={this.state.piDipFobInfo.status}
              mclass={this.state.piDipFobInfo.status}
              mcount={this.state.piDipFobInfo.status}
              mcarton={this.state.piDipFobInfo.status}
              mweek={this.state.piDipFobInfo.status}
              mbrand={this.state.piDipFobInfo.status}
            />
          </Confirmation>
        )}
        {this.state.foreignCostInfo && (
          <Confirmation
            title={"Foreign Cost Info"}
            isOpen={true}
            handleClose={() => this.handleForeignCostInfo(undefined)}
            handleConfirm={() => this.handleForeignCostInfo(undefined)}
            body={undefined}
          >
            <ForeignCostInfo barcodeid={this.state.foreignCostInfo.barcode_id} />
          </Confirmation>
        )}

        <div className={classes.inlineRight}>
          <ConsolidationEnqueueTimer
            refreshChanged={this.state.detailLoading}
            shouldRefresh={this.state.shouldRefresh}
            toggleRefreshTimer={this.toggleRefreshTimer}
            refreshTimerCount={this.state.refreshTimerCount}
          />
        </div>
        <div className={classes.gridContainer}>
          <div style={{ position: "absolute", right: "10px", top: "0", zIndex: 1000 }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
              <p style={{ fontSize: "14px", color: "white" }}>Last run was {this.getLastDate()}</p>
              <div>
                <OrangeButton type="button" variant="contained" onClick={this.handleOpenQueueDialog}>
                  update queue
                </OrangeButton>
              </div>
            </div>
          </div>
          <AppBar position="static">
            <Tabs value={this.state.tabValue} onChange={(_, value) => this.setState({ tabValue: value })} aria-label="simple tabs example">
              <Tab label="Unapproved" {...a11yProps(Status.Unapproved)} />
              <Tab label="Waiting for approval" {...a11yProps(Status.Pending)} />
              <Tab label="Approved" {...a11yProps(Status.Approved)} />
              <Tab label="Processed" {...a11yProps(Status.Processed)} />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.tabValue} index={Status.Unapproved} className={classes.tabpanel}>
            <div id="main_splitter_div" style={{ height: "100%", width: "100%", display: "grid" }}>
              <Splitter
                className={classes.splitter}
                primaryPaneWidth={this.state.primaryGridSize}
                primaryPaneMinWidth="20%"
                primaryPaneMaxWidth="80%"
                position="vertical"
                dispatchResize={true}
                postPoned={true}
                minimalizedPrimaryPane={this.state.minimizeGrid}
                onDragFinished={(e) => {}}
              >
                <div style={{ visibility: this.state.minimizeGrid ? "hidden" : "visible" }}>
                  <div className={classes.inline}>
                    <DocumentsButton menuItems={this.menuItems} disabled={this.state.selectedDataLoading || (this.state.selectedRows || []).length === 0} />
                  </div>
                  <Grid
                    loading={this.state.loading}
                    data={this.state.groupedData}
                    clearFilters={"consolidationgrouped"}
                    forceHeight={this.state.mainGridHeight}
                    handleRefresh={this.handleGroupedRefresh}
                    handleFilteredRows={this.handleGroupedFilteredRows}
                    rowRenderer={(props) => this.rowRenderer(props, classes)}
                    GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                      GroupedGridColumns(
                        data,
                        filters,
                        columnArrangement,
                        columnsWidth,
                        this.handleRowClick,
                        this.state.selectedRows,
                        this.handleClearAmendDetails,
                        this.handleClearGroupSelected,
                        this.selectAllGrouped,
                      )
                    }
                    showFilterChips={true}
                    refreshButtonColor={this.state.shouldRefresh ? "pink" : "orange"}
                    totalRowColumns={["noInvoices", "barcode_nocartons", "barcode_palletsize", "total"]}
                  />
                </div>
                <div>
                  {this.state.unnapprovedGrouped && (
                    <GroupedUnapprovedTable
                      filter={this.state.globalFilter}
                      data={this.state.selectedData}
                      loading={this.state.selectedDataLoading}
                      mainGridHeight={this.state.mainGridHeight}
                      selectedRows={this.state.unapprovedSelectedRows}
                      handleGroupRowsSelected={this.selectUnpprovedRowGrouped}
                      handleUnapprovedRefresh={this.handleUnapprovedRefresh}
                      handleProcessUnapproved={this.handleProcessUnapproved}
                      handleUpdateSelectedData={this.handleUpdateSelectedData}
                      handleClearUnapprovedSelectedRows={this.clearUnapprovedSelectedRows}
                      groupChecked={this.state.unnapprovedGrouped}
                      groupOnChange={this.handleGroupData}
                    />
                  )}
                  {!this.state.unnapprovedGrouped && (
                    <div className={classes.toolbarWrapper}>
                      <Toolbar className={classes.toolbar}>
                        <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}>
                          <Button
                            type="button"
                            style={{ marginRight: "10px" }}
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                              this.handleProcessUnapproved(Status.Unapproved);
                            }}
                          >
                            save
                          </Button>
                          <GreenButton
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              this.handleProcessUnapproved(Status.Pending);
                              // this.handleStatusChange(this.state.unapprovedSelectedRows, Status.Pending);
                            }}
                            disabled={this.state.unapprovedSelectedRows.length === 0}
                          >
                            approve selected
                          </GreenButton>
                          <GreyButton type="button" variant="contained" onClick={this.handleToggleGridMinimize}>
                            {this.state.minimizeGrid ? "Minimize" : "Maximize"}
                          </GreyButton>
                          <FormControlLabel
                            control={<Switch inputProps={{ "aria-label": "primary checkbox" }} checked={this.state.unnapprovedGrouped} onChange={this.handleGroupData} />}
                            label="Group"
                          />
                        </div>
                      </Toolbar>
                      <div>
                        <Grid
                          fill={true}
                          data={this.state.selectedData}
                          clearFilters={"consolidationselected"}
                          forceHeight={this.state.mainGridHeight}
                          loading={this.state.selectedDataLoading}
                          handleRefresh={this.handleUnapprovedRefresh}
                          rowRenderer={(props) => this.unapprovedRowRenderer(props, classes)}
                          handleChange={(column, rows) => this.handleRowsUpdated(column, rows)}
                          handleExport={this.handleExport}
                          handleFilteredRows={this.handleUnapprovedFilteredRows}
                          selectedRowChange={this.handleGridBarcodeSelectedRowChange}
                          GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                            BarcodeGridColumns(
                              data,
                              filters,
                              columnArrangement,
                              columnsWidth,
                              true,
                              false,
                              this.clearUnapprovedSelectedRows,
                              this.selectAllUnapproved,
                              this.selectUnapprovedRow,
                              this.state.unapprovedSelectedRows,
                              true,
                              this.handlePIInfo,
                              this.handleForeignCostInfo,
                            )
                          }
                          totalRowColumns={totalRows}
                          showFilterChips={true}
                        />
                        {createPortal(
                          <ContextMenu id="grid-context-menu" className={classes.contextMenu}>
                            <MenuItem className={classes.contextMenuItem} onClick={(_, data) => this.handleApplyCellValue(data, false)}>
                              Apply
                            </MenuItem>
                            <MenuItem className={classes.contextMenuItem} onClick={(_, data) => this.handleApplyCellValue(data, true)}>
                              Apply All
                            </MenuItem>
                          </ContextMenu>,
                          document.body,
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Splitter>
            </div>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={Status.Pending} className={classes.tabpanel}>
            {this.state.pendingGrouped && (
              <GroupedPendingTable
                filter={this.state.globalFilter}
                loading={this.state.detailLoading}
                mainGridHeight={this.state.mainGridHeight}
                data={this.state.consolidationDataDetailWaiting}
                selectedRows={this.state.pendingSelectedData}
                handleGroupRowsSelected={this.selectPendingRowGrouped}
                handleDetailRefresh={this.handleGroupedRefresh}
                handleOnAcceptPending={() => this.handleSelectedRecordsProcess(this.state.consolidationDataDetailWaiting, undefined)}
                handleApproveSelected={() => this.handleSelectedRecordsProcess(this.state.pendingSelectedData, Status.Approved)}
                handleUnapprovePendingSelected={() => this.handleSelectedRecordsProcess(this.state.pendingSelectedData, Status.Unapproved)}
                handlePendingGroupRowsSelected={this.handlePendingGroupRowsSelected}
                handleGroupUpdateSelectedData={this.handleGroupUpdateSelectedData}
                groupChecked={this.state.pendingGrouped}
                groupOnChange={this.handleChangePendingDataGroup}
              />
            )}
            {!this.state.pendingGrouped && (
              <div className={classes.toolbarWrapper}>
                <Toolbar className={classes.toolbar}>
                  <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}>
                    <Button
                      type="button"
                      style={{ marginRight: "10px" }}
                      color="primary"
                      variant="outlined"
                      onClick={() => this.handleSelectedRecordsProcess(this.state.consolidationDataDetailWaiting, undefined)}
                    >
                      save
                    </Button>
                    <GreenButton
                      type="button"
                      variant="contained"
                      color="primary"
                      // onClick={() => this.handleStatusChange(this.state.pendingSelectedData, Status.Approved)}
                      onClick={() => this.handleSelectedRecordsProcess(this.state.pendingSelectedData, Status.Approved)}
                      disabled={this.state.pendingSelectedData.length === 0}
                    >
                      Approve Selected
                    </GreenButton>
                    <RedButton
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleSelectedRecordsProcess(this.state.pendingSelectedData, Status.Unapproved)}
                      // onClick={() => this.handleStatusChange(this.state.pendingSelectedData, Status.Approved)}
                      disabled={this.state.pendingSelectedData.length === 0}
                    >
                      Unapprove Selected
                    </RedButton>
                    <FormControlLabel
                      control={<Switch inputProps={{ "aria-label": "primary checkbox" }} checked={this.state.pendingGrouped} onChange={this.handleChangePendingDataGroup} />}
                      label="Group"
                    />
                  </div>
                </Toolbar>
                <Grid
                  data={this.state.consolidationDataDetailWaiting}
                  GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                    BarcodeGridColumns(
                      data,
                      filters,
                      columnArrangement,
                      columnsWidth,
                      false,
                      false,
                      this.handleClearWaitingSelected,
                      this.handleSelectAllPendingRows,
                      this.handlePendingRowDoubleClick,
                      this.state.pendingSelectedData,
                      true,
                      this.handlePIInfo,
                      this.handleForeignCostInfo,
                    )
                  }
                  handleRefresh={this.handleGroupedRefresh}
                  clearFilters={"consolidationselected"}
                  forceHeight={this.state.mainGridHeight}
                  loading={this.state.detailLoading}
                  rowRenderer={(props) => this.pendingRowRenderer(props, classes)}
                  handleChange={(column, rows) => this.handleRowsUpdatedPending(column, rows)}
                  fill={true}
                  handleFilteredRows={this.handlePendingFilteredRows}
                  selectedRowChange={this.handleGridBarcodeSelectedRowChange}
                  totalRowColumns={totalRows}
                  showFilterChips={true}
                  refreshButtonColor={this.state.shouldRefresh ? "pink" : "orange"}
                />
                {createPortal(
                  <ContextMenu id="grid-context-menu" className={classes.contextMenu}>
                    <MenuItem className={classes.contextMenuItem} onClick={(_, data) => this.handleApplyCellValueWaiting(data, false)}>
                      Apply
                    </MenuItem>
                    <MenuItem className={classes.contextMenuItem} onClick={(_, data) => this.handleApplyCellValueWaiting(data, true)}>
                      Apply All
                    </MenuItem>
                  </ContextMenu>,
                  document.body,
                )}
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={Status.Approved} className={classes.tabpanel}>
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbar}>
                <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}>
                  <GreenButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSelectedRecordsProcess(this.state.approvedSelectedData, Status.Processed)}
                    disabled={this.state.approvedSelectedData.length === 0}
                  >
                    Process Selected
                  </GreenButton>
                  <RedButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSelectedRecordsProcess(this.state.approvedSelectedData, Status.Pending)}
                    disabled={this.state.approvedSelectedData.length === 0}
                  >
                    Unapprove Selected
                  </RedButton>
                </div>
              </Toolbar>
              <Grid
                data={this.state.consolidationDataDetailApproved}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  BarcodeGridColumns(
                    data,
                    filters,
                    columnArrangement,
                    columnsWidth,
                    false,
                    false,
                    this.handleClearApprovedSelected,
                    this.handleSelectAllApprovedRows,
                    this.handleApprovedRowDoubleClick,
                    this.state.approvedSelectedData,
                    undefined,
                    this.handlePIInfo,
                    this.handleForeignCostInfo,
                  )
                }
                handleRefresh={this.handleDetailRefresh}
                clearFilters={"consolidationselected"}
                forceHeight={this.state.mainGridHeight}
                loading={this.state.detailLoading}
                rowRenderer={(props) => this.approvedRowRenderer(props, classes)}
                handleFilteredRows={this.handleApprovedFilteredRows}
                selectedRowChange={this.handleGridBarcodeSelectedRowChange}
                totalRowColumns={totalRows}
                showFilterChips={true}
              />
            </div>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={Status.Processed} className={classes.tabpanel}>
            <div className={classes.toolbarWrapper}>
              <Toolbar className={classes.toolbar}>
                <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}>
                  <RedButton
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSelectedRecordsProcess(this.state.processedSelectedRows, Status.Approved)}
                    disabled={this.state.processedSelectedRows.length === 0}
                  >
                    Unapprove Selected
                  </RedButton>
                  {this.state.canLockConsolidation && (
                    <OrangeButton
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleLockSelectedRows()}
                      disabled={this.state.processedSelectedRows.length === 0}
                    >
                      Toggle Lock
                    </OrangeButton>
                  )}
                </div>
              </Toolbar>
              <Grid
                data={this.state.consolidationDataDetailProcessed}
                GridColumns={(data, filters, columnArrangement, columnsWidth) =>
                  BarcodeGridColumns(
                    data,
                    filters,
                    columnArrangement,
                    columnsWidth,
                    false,
                    false,
                    this.clearAllProcessedSelectedRows,
                    this.selectAllProcessedData,
                    this.handleProcessedRowDoubleClick,
                    this.state.processedSelectedRows,
                    undefined,
                    this.handlePIInfo,
                    this.handleForeignCostInfo,
                  )
                }
                handleRefresh={this.handleDetailRefresh}
                clearFilters={"consolidationselected"}
                forceHeight={this.state.mainGridHeight}
                loading={this.state.detailLoading}
                handleFilteredRows={this.handleProcessedFilteredRows}
                rowRenderer={(props) => this.processedRowRenderer(props, classes)}
                selectedRowChange={this.handleGridBarcodeSelectedRowChange}
                totalRowColumns={totalRows}
                showFilterChips={true}
              />
            </div>
          </TabPanel>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ConsolidationUnstyled);
