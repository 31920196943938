import React, { useState } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import { RenderIcon } from "../components/MenuListItem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: `${theme.spacing(1)}px`,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      alignItems: "center",

      "&:hover": {
        background: "rgb(221, 221, 221)",
      },
    },
    popperItem: {
      cursor: "pointer",
      color: "#333",
    },
  });

type ActionList = {
  text: string;
  icon?: any;
  callback?: (e: any) => void;
};

type Action = {
  icon: any | any[];
  actions?: ActionList[];
  callback?: (e: any) => void;
};

export type CellActionsFormatterProps = {
  classes: any;
  actions: Action[];
  disabled?: boolean;
} & WithStyles<typeof styles>;

const CellActionsFormatterUnstyled: React.FC<CellActionsFormatterProps> = ({ classes, actions, disabled }) => {
  return (
    <>
      {actions.map((action, index) =>
        action.actions && action.actions.length > 0 ? (
          <ActionsPopper action={action} classes={classes} key={`popper-${index}`} disabled={disabled} />
        ) : (
          <SingleAction action={action} classes={classes} key={`single-${index}`} disabled={disabled} />
        ),
      )}
    </>
  );
};

export const CellActionsFormatter = withStyles(styles)(CellActionsFormatterUnstyled);

const ActionsPopper: React.FC<{ action: Action; classes: any; disabled: boolean }> = ({ action, classes, disabled }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleCallback = (callback) => {
    if (disabled) return;

    callback();
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <span onClick={handleClick}>{action.icon}</span>
      <Menu style={{ zIndex: 10000 }} id="actions-popper" open={open} anchorEl={anchorEl} keepMounted onClose={() => setOpen(false)}>
        {action.actions.map((actionlist: ActionList, index: number) => (
          <MenuItem onClick={() => handleCallback(actionlist.callback)} className={classes.paper} key={index}>
            {typeof actionlist.icon == "object" ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <RenderIcon icon={actionlist.icon[0]} />
                <RenderIcon icon={actionlist.icon[1]} />
              </div>
            ) : (
              <span>{actionlist.icon ? <RenderIcon icon={actionlist.icon} /> : <></>}</span>
            )}
            <span className={classes.popperItem}>{actionlist.text}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const SingleAction: React.FC<{ action: Action; classes: any; disabled: boolean }> = ({ action, classes, disabled }) => {
  return (
    <span onClick={disabled ? () => {} : action.callback} style={{ textAlign: "right", color: "red" }}>
      {action.icon}
    </span>
  );
};
