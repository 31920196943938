import React, { useContext, useState, useEffect, FC } from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";

import Grid from "../../lib/components/grid";
import Confirmation from "../../lib/components/confirmation";
import { GridColumns } from "./advancecontractgridsetup";
import AdvanceContractForm from "./advancecontractform";
import { advanceContracts, advancecontractsFull, advancecontractsCopy, advanceContractsPaidLink, upsertAdvanceContract } from "../../lib/api/advancecontracts";
import { SnackContext } from "../../lib/context/SnackContext";
import { commoditiesAllSortedMappedforCombo } from "../../lib/api/commodity";
import { varietyAllSortedMappedforCombo } from "../../lib/api/variety";
import { classesAllSortedMappedforCombo } from "../../lib/api/classes";
import { DialogInformation } from "../../lib/components/dialoginformation";
import { LinkedGridColumns } from "./advancecontractlinkedgridsetup";
import { producerAdvanceByContractId } from "../../lib/api/produceradvancepaid";
import { GenerateErrorMessage } from "../../lib/helpers/string_methods";
import { getDistinctPackCodes } from "../../lib/api/pack";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
      height: "100%",
      position: "relative",
    },
    inline: {
      display: "inline",
      position: "absolute",
      marginTop: "7px",
      marginLeft: "7px",
    },
    infoGridWrapper: {
      width: "700px",
    },
  });

type AdvanceContractProps = {
  producerid?: number;
  handleUpdateProducer: () => void;
} & WithStyles<typeof styles>;

const AdvanceContractUnstyled: React.FunctionComponent<AdvanceContractProps> = ({ classes, producerid, handleUpdateProducer }) => {
  const { updateSnack } = React.useContext(SnackContext);

  const [data, setData] = useState([]);

  const [confirmRemoveID, setConfirmRemoveID] = useState(undefined);
  const [confirmEditID, setConfirmEditID] = useState(undefined);
  const [confirmCopyID, setConfirmCopyID] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const [isDuplicate, setIsDuplicate] = useState(false);
  const [commodities, setCommodities] = useState([]);
  const [itemExists, setItemExists] = useState(false);
  const [varieties, setVarieties] = useState([]);
  const [grades, setGrades] = useState([]);
  const [packcodes, setPackcodes] = useState([]);

  const [linkedContracts, setLinkedContracts] = useState([]);
  const [showLinkedData, setShowLinkedData] = useState(false);
  const [advanceContractSelected, setAdvanceContractSelected] = useState(undefined);
  const [linkedToAdvance, setLinkedToAdvance] = useState(false);

  useEffect(() => {
    loadData();
  }, [producerid]);

  const loadData = async () => {
    setLoading(true);

    const [commoditiesData, varietiesData, gradesData, packCodesData, linkedContractsData, advanceContracts] = await Promise.all([
      commoditiesAllSortedMappedforCombo(),
      varietyAllSortedMappedforCombo(),
      classesAllSortedMappedforCombo(),
      getDistinctPackCodes(),
      advanceContractsPaidLink(),
      advancecontractsFull(undefined, producerid),
    ]);

    setCommodities(commoditiesData);
    setVarieties(varietiesData);
    setGrades(gradesData);
    setPackcodes(packCodesData);
    setLinkedContracts(linkedContractsData);
    setData(advanceContracts);
    setLoading(false);
  };

  const handleCreate = () => {
    setConfirmEditID("0");
  };

  const handleEdit = async (id: number) => {
    try {
      const advanceResult = await producerAdvanceByContractId(id);
      if (advanceResult.length > 0) {
        setLinkedToAdvance(true);
        return;
      }
      setIsDuplicate(false);
      setConfirmEditID(id);
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to retreive data");
      updateSnack({ show: true, message: err, color: "red" });
    }
  };

  const handleEditClose = () => {
    setItemExists(false);
    setIsDuplicate(false);
    setConfirmEditID(undefined);
    loadData();
  };

  const sortStr = (str: string) => {
    if (str) {
      const arr = str.split(",");
      const sorted = arr.map((a) => a && a.trim()).sort();
      const returnStr = sorted.join(",");
      return returnStr;
    } else {
      return null;
    }
  };

  const getCommoditiesStr = (arr: []) => {
    if (arr) {
      const commoditiesArr = [];

      for (let i = 0; i < arr.length; i++) {
        const commodity = commodities.find((c) => c.id == arr[i]);
        if (commodity) {
          commoditiesArr.push(commodity.value);
        }
      }

      const str = commoditiesArr.join(",");
      const sorted = sortStr(str);
      return sorted;
    } else {
      return null;
    }
  };

  const getVarietiesStr = (arr: []) => {
    if (arr) {
      const varietiesArr = [];

      for (let i = 0; i < arr.length; i++) {
        const variety = varieties.find((c) => c.value == arr[i]);
        if (variety && variety.data) {
          varietiesArr.push(variety.data.code);
        }
      }

      const str = varietiesArr.join(",");
      const sorted = sortStr(str);
      return sorted;
    } else {
      return null;
    }
  };

  const getGradesStr = (arr: []) => {
    if (arr) {
      const gradesArr = [];

      for (let i = 0; i < arr.length; i++) {
        const grade = grades.find((c) => c.id == arr[i]);
        if (grade) {
          gradesArr.push(grade.value);
        }
      }

      const str = gradesArr.join(",");
      const sorted = sortStr(str);
      return sorted;
    } else {
      return null;
    }
  };

  const getPackCodeStr = (arr: []) => {
    if (arr) {
      const packCodesArr = [];

      for (let i = 0; i < arr.length; i++) {
        const packcode = packcodes.find((c) => c.code == arr[i]);
        if (packcode) {
          packCodesArr.push(packcode.code);
        }
      }

      const str = packCodesArr.join(",");
      const sorted = sortStr(str);
      return sorted;
    } else {
      return null;
    }
  };

  const checkDuplicate = (item) => {
    const itemCommodities = item["commodity"];
    const itemVarieties = item["variety"];
    const itemGrades = item["grade"];
    const itemPackCodes = item["packcode"];

    const sortedItemCommodities = getCommoditiesStr(itemCommodities);
    const sortedItemVarieties = getVarietiesStr(itemVarieties);
    const sortedItemGrades = getGradesStr(itemGrades);
    const sortedItemPackCodes = getPackCodeStr(itemPackCodes);

    const exists = data.find((row) => {
      const rowCommodities = sortStr(row.commodities);
      const rowVarieties = sortStr(row.varieties);
      const rowGrades = sortStr(row.grades);
      const rowPackCodes = sortStr(row.packcodes);

      if (
        item.id != row.id &&
        item.producer_id == row.producer_id &&
        sortedItemCommodities == rowCommodities &&
        sortedItemVarieties == rowVarieties &&
        sortedItemGrades == rowGrades &&
        sortedItemPackCodes == rowPackCodes &&
        item.weekfrom == row.weekfromid &&
        item.weekto == row.weektoid
      ) {
        return row;
      }
      return null;
    });

    return exists;
  };

  const handleEditConfirm = async (item) => {
    setLoading(true);
    try {
      if (checkDuplicate(item)) {
        setItemExists(true);
        return;
      }

      await upsertAdvanceContract({
        contract: {
          id: item.id,
          weekfrom: item.weekfrom,
          weekto: item.weekto,
          cad: item.cad,
          eur: item.eur,
          gbp: item.gbp,
          usd: item.usd,
          zar: item.zar,
          producer_id: item.producer_id,
          payoutSource: item.payoutSource,
          payoutLeadNumber: item.payoutLeadNumber,
        },
        commodities: item.commodity,
        varieties: item.variety,
        grades: item.grade,
        packCodes: item.packcode,
      });

      handleEditClose();
      handleUpdateProducer();
      loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to submit Contract");
      updateSnack({ show: true, message: err, color: "red" });
    }
    setLoading(false);
  };

  const handleRemove = async (id) => {
    setConfirmRemoveID(id);
  };

  const handleRemoveClose = () => {
    setConfirmRemoveID(undefined);
  };

  const handleCopy = (id) => {
    setIsDuplicate(true);
    setConfirmCopyID(id);
  };

  const handleCopyComplete = async (id) => {
    if (id) {
      const createId = await advancecontractsCopy(confirmCopyID);
      await loadData();
      if (createId.length > 0) {
        setConfirmEditID(createId[0].id);
        setConfirmCopyID(undefined);
      }
    } else {
      loadData();
      setConfirmCopyID(undefined);
    }
  };

  const handleRemoveConfirm = async () => {
    setLoading(true);
    try {
      await advanceContracts.remove(confirmRemoveID);
      handleRemoveClose();
      loadData();
    } catch (error) {
      const err = GenerateErrorMessage(error, "Failed to remove Contract");
      updateSnack({ show: true, message: err, color: "red" });
    }
    setLoading(false);
  };

  const handleInfo = (row) => {
    setAdvanceContractSelected(row);
    setShowLinkedData(true);
  };

  const GridColumnsOverride = (data, filters, arrangement, columnsWidth) => {
    const linkedData = (data || []).map((row) => ({ ...row, linked: Boolean(linkedContracts.find((contract) => contract.contract_id == row.id)) }));
    return GridColumns(linkedData, filters, arrangement, columnsWidth, handleRemove, handleEdit, handleCopy, handleInfo);
  };

  const handleCloseLinkedData = () => {
    setShowLinkedData(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.inline}>
        <Tooltip style={{ zIndex: 0 }} title="Create new Advances Contract">
          <div className={classes.inline}>
            <Button variant="contained" color="primary" onClick={handleCreate} style={{ marginBottom: "10px" }}>
              <IconAdd />
            </Button>
          </div>
        </Tooltip>
      </div>
      <Grid loading={loading} data={data} GridColumns={GridColumnsOverride} handleRefresh={() => loadData()} clearFilters={"advancecontractgrid"} />
      {confirmRemoveID && (
        <Confirmation
          isOpen={confirmRemoveID ? true : false}
          handleClose={handleRemoveClose}
          handleConfirm={handleRemoveConfirm}
          title="Remove selected Advances Contract?"
          body="Are you sure you want to REMOVE the selected Advances Contract Record?"
        ></Confirmation>
      )}
      {confirmEditID && (
        <Confirmation
          isOpen={confirmEditID ? true : false}
          handleClose={handleEditClose}
          handleConfirm={() => {}}
          title={confirmEditID === "0" ? "Advances Contract" : "Editing Advances Contract"}
          body={undefined}
          disableBackdropClick={true}
        >
          <AdvanceContractForm
            id={confirmEditID}
            itemExists={itemExists}
            producerid={producerid}
            isDuplicate={isDuplicate}
            onClose={handleEditClose}
            onSubmit={handleEditConfirm}
          />
        </Confirmation>
      )}
      {confirmCopyID && (
        <Confirmation
          isOpen={true}
          handleClose={() => handleCopyComplete(undefined)}
          handleConfirm={() => handleCopyComplete(confirmCopyID)}
          title={"Copy Advances Contract?"}
          body={"Are you sure you want to COPY the selected Advances Contract Record?"}
        ></Confirmation>
      )}
      {showLinkedData && (
        <DialogInformation
          isOpen={true}
          handleClose={handleCloseLinkedData}
          handleOK={handleCloseLinkedData}
          title={"Linked advance contracts"}
          body={
            <div className={classes.infoGridWrapper}>
              <Grid
                loading={false}
                forceHeight={500}
                clearFilters={"advancecontractslinked"}
                GridColumns={LinkedGridColumns}
                data={linkedContracts.filter((contract) => contract.contract_id == advanceContractSelected.id)}
              />
            </div>
          }
          showinput={false}
        />
      )}
      {linkedToAdvance && (
        <DialogInformation
          isOpen
          showinput={false}
          title={"Editing Restricted"}
          body={"A producer remittance is linked to this contract. Please reverse the payment before proceeding with any edits."}
          handleOK={() => setLinkedToAdvance(false)}
          handleClose={() => setLinkedToAdvance(false)}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(AdvanceContractUnstyled);
