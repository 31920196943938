import React from "react";

import CancelIcon from "@material-ui/icons/Cancel";

import IconClear from "@material-ui/icons/Clear";
import IconDone from "@material-ui/icons/Done";

import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { CheckboxSelect } from "../../lib/components/CheckboxSelect";
import { DealType } from "../../lib/api/dealtype";

import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";
import Checkbox from "@material-ui/core/Checkbox";
import { Status } from "./consolidation";

const cellWidthActionSmall = 30;
const cellWidthAction = 50;
const cellWidthSmall = 60;
// const cellWidthMedium = 120;
// const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

const StatusFormatter = ({ row }) => {
  const status = row.camend_status_text;
  switch (status) {
    case "WAITING":
      return (
        <div>
          <Tooltip title={"WAITING"}>
            <IconBrightness style={{ color: "#4761e6", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
          </Tooltip>
        </div>
      );
    case "APPROVED":
      return (
        <div>
          <Tooltip title={"APPROVED"}>
            <IconBrightness style={{ color: "green", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
          </Tooltip>
        </div>
      );
    case "PROCESSED":
      return (
        <div>
          <Tooltip title={"PROCESSED"}>
            <IconBrightness style={{ color: "purple", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
          </Tooltip>
        </div>
      );
    case "PAID":
      return (
        <div>
          <Tooltip title={"PAID"}>
            <IconBrightness style={{ color: "red", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
          </Tooltip>
        </div>
      );
    default:
      return (
        <div>
          <Tooltip title={"UNAPPROVED"}>
            <IconBrightness style={{ color: "orange", display: "block", margin: "auto", border: "1px solid #333", borderRadius: "100%" }} />
          </Tooltip>
        </div>
      );
  }
};

export const GroupedGridColumns = (data, filters, arrangement, columnsWidth, handleCheckboxSelect, selectedRows, clearAmendDetails, handleClearGroupSelected, selectAllGrouped) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "",
      name: "#A" + postValue,
      width: cellWidthAction,
      formatter: ({ row }) => {
        return (
          <div style={{ display: "flex", float: "left" }}>
            <div style={{ marginTop: "-8px" }}>
              <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} valueKey={"invoicenumber"} />
            </div>
            {row.camend_id > 0 && row.camend_status < Status.Processed && <CancelIcon style={{ cursor: "pointer", color: "red" }} onClick={() => clearAmendDetails(row)} />}
          </div>
        );
      },
      filterRenderer: (e) => (
        <div>
          <Checkbox
            color="default"
            checked={selectedRows.length > 0}
            onChange={selectedRows.length === 0 ? selectAllGrouped : handleClearGroupSelected}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
      ),
    },
    {
      key: "camend_status_text",
      name: "Status" + postValue,
      width: cellWidthActionSmall,
      formatter: StatusFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"camend_status_text"} data={data} section="consolidationgrouped" />;
      },
    },
    {
      key: "username",
      name: "User" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"username"} data={data} section="consolidationgrouped" />;
      },
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthSmall,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
      summaryFormatter: ({ row, column }) => <SummaryNumber row={row} column={{ ...column, key: "noInvoices" }} />,
    },
    {
      key: "producer_codes",
      name: "Producer(s)" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer_codes"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "loadout_consignee",
      name: "Client" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_consignee"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_dealtype",
      name: "Deal Type" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_dealtype"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
      formatter: (data) => <div style={{ textAlign: "center" }}>{DealType[data.row.dispatch_dealtype]}</div>,
    },
    {
      key: "currency_code",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency_code"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_loaddate",
      name: "Invoice Date" + postValue,
      width: cellWidthSmall,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_loaddate"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "sale_accsalereceived",
      name: "A/S Rec" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_accsalereceived"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
      formatter: (data) =>
        data.row.clients_sellingterms == 2 ? (
          <div style={{ textAlign: "center" }}>N/A</div>
        ) : data.row.sale_accsalereceived == 1 ? (
          <div style={{ textAlign: "center" }}>
            <IconDone />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <IconClear />
          </div>
        ),
    },
    {
      key: "total_outstanding",
      name: "Inv Outstanding" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total_outstanding"} data={data} section="consolidationgrouped" />;
      },
    },
    {
      key: "barcode_nocartons",
      name: "Total Cartons" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_nocartons"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "barcode_palletsize",
      name: "Total Pallets" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode_palletsize"} data={data} section="consolidationgrouped" />;
      },
      filterable: false,
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "total",
      name: "Total Sale" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"total"} data={data} section="consolidationgrouped" />;
      },
      filterable: false,
      summaryFormatter: SummaryNumber,
    },
    {
      key: "orderno",
      name: "Order No" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orderno"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "sale_exchangerate",
      name: "Exchange Rate" + postValue,
      width: cellWidthSmall,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_exchangerate"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "target_market",
      name: "Target Market" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_market"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "portfinal_code",
      name: "Destination" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "portdistcharge_code",
      name: "Discharge" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdistcharge_code"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "loadout_voyageNumber",
      name: "Shipping No" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_voyageNumber"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_containerno",
      name: "Container No" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_containerno"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_sealnumber",
      name: "Seal No" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_sealnumber"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "loadout_etd",
      name: "ETD" + postValue,
      width: cellWidthSmall,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_etd"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "loadout_eta",
      name: "ETA" + postValue,
      width: cellWidthSmall,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_eta"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "loadout_updated_eta",
      name: "Updated ETA" + postValue,
      width: cellWidthSmall,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"loadout_updated_eta"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "shipment_week",
      name: "Shipment Week" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipment_week"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "shippingCode",
      name: "Shipping Line" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "portloading_code",
      name: "Port of Loading" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portloading_code"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "dispatch_dispatchdocs_id",
      name: "Dispatch" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"dispatch_dispatchdocs_id"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "arrival_average",
      name: "Quality" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"arrival_average"} data={data} section="consolidationgrouped" />;
      },
    },
    {
      key: "sale_ucr",
      name: "UCR" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_ucr"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
    {
      key: "users_username",
      name: "Trader" + postValue,
      width: cellWidthSmall,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"users_username"} data={data} section="consolidationgrouped" />;
      },
      filterable: true,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};
