import React from "react";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import ArrivalsDetailHeader from "./arrivalsdetailheader";
import { ArrivalsDetailGrid } from "./arrivalsdetailgrid";
import ArrivalsDetailComments from "./arrivalsdetailcomments";
import ImageListDropzone from "./arrivalsdetailImageDropZone";
import { ArrivalActions } from "./arrivalActions";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      width: "100%",
    },
    buttonActions: {
      display: "flex",
      marginBottom: theme.spacing(1),
    },
    header: {},
    tableCellDesc: {
      paddingRight: theme.spacing(1),
      display: "block",
      maxwidth: "100% !important",
    },
    arrivalGrid: {
      display: "grid",
      gap: theme.spacing(2),
    },
  });

type ArrivalsDetailProps = {
  container: string;
  producerid: string;
  salesref: string;
  arrival_id: Number;
  status: Number;
  handleClose: () => void;
  handleExportExcel: () => void;
  handleExportPDF: () => void;
  handleSubmit: (exportRequested, status, claim) => any;
  handleChange: (property, value) => void;
  claim: boolean;
} & WithStyles<typeof styles>;

const ArrivalsDetailUnstyled: React.SFC<ArrivalsDetailProps> = (props) => {
  const { classes, container, producerid, salesref, arrival_id, status, handleClose, handleSubmit, handleExportExcel, handleExportPDF, handleChange, claim } = props;
  const [loading, setLoading] = React.useState(false);

  const handleSubmitLocal = (complete: boolean = false, claim) => {
    setLoading(true);
    handleSubmit(false, complete ? 2 : 1, claim);
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttonActions}>
        <ArrivalActions
          handleClose={handleClose}
          handleSubmitLocal={handleSubmitLocal}
          handleExportExcel={handleExportExcel}
          handleExportPDF={handleExportPDF}
          status={status}
          claim={claim}
        />
        {loading && <CircularProgress />}
      </div>
      <div className={classes.arrivalGrid}>
        <div className={classes.header}>
          <ArrivalsDetailHeader container={container} producerid={producerid} salesref={salesref} />
        </div>
        <div>
          <ArrivalsDetailGrid container={container} producerid={producerid} salesref={salesref} arrival_id={arrival_id} handleChange={handleChange} />
        </div>
        <div>
          <ArrivalsDetailComments arrival_id={arrival_id} handleChange={handleChange} />
        </div>
        <div>
          <Paper>
            <ImageListDropzone arrival_id={arrival_id} handleChange={handleChange} />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ArrivalsDetailUnstyled);
