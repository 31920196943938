import React from "react";

import IconInfo from "@material-ui/icons/Info";

import { CheckboxSelect } from "../../lib/components/CheckboxSelect";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";
import { EnquiryLink, Selector } from "../../lib/components/EnquiryLink";
import { DateFormatter, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { SummaryNumber, SummaryNumber2Decimal, SummaryTotal } from "../../lib/components/SummaryFormatter";
import TextEditor from "../../lib/components/TextEditor";

import { ContextMenuTrigger } from "react-contextmenu";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";

import numeral from "numeral";
import { Status } from "./consolidation";
import { DealType } from "../../lib/api/dealtype";

import IconLock from "@material-ui/icons/Lock";
import Checkbox from "@material-ui/core/Checkbox";

const SummaryNumber2DecimalFieldSpecify = ({ data, field }) => {
  try {
    const { row, column } = data;
    return <div style={{ textAlign: "right", fontWeight: "bold" }}>{numeral(row[field]) ? numeral(row[field]).format("0,0.00") : "0.00"}</div>;
  } catch (e) {
    console.log("e->", e);
  }
};

const ContextMenuContainer: React.FC<{ children: any; row: any; column: any }> = ({ children, row, column }) => {
  return (
    <ContextMenuTrigger id="grid-context-menu" collect={() => ({ row, column: column.key })}>
      {children}
    </ContextMenuTrigger>
  );
};

const cellWidthTiny = 40;
const cellWidthSmall = 80;
const cellWidthMedium = 120;
const cellWidthLarge = 200;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;

export const BarcodeGridColumns = (
  data,
  filters,
  arrangement,
  columnsWidth,
  canHide,
  showTotals,
  clearSelected,
  selectAll,
  handleCheckboxSelect?,
  selectedRows?,
  canEdit = false,
  handlePIInfoClick = undefined,
  handleForeignCostInfo = undefined,
) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "Checkbox",
      name: "#C" + postValue,
      width: cellWidthTiny,
      formatter: ({ row }) => (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {row.locked || row.camend_status == Status.Paid ? <IconLock style={{ color: "orange" }} /> : <></>}
          {row.camend_status == Status.Paid ? (
            <></>
          ) : canHide ? (
            row.camend_status == 0 ? (
              <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} valueKey={"barcode_id"} />
            ) : (
              <></>
            )
          ) : (
            <CheckboxSelect row={row} selectedRows={selectedRows} handleCheckboxSelect={handleCheckboxSelect} valueKey={"barcode_id"} />
          )}
        </div>
      ),
      filterRenderer: (e) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Checkbox
            color="default"
            checked={selectedRows.length > 0}
            onChange={selectedRows.length === 0 ? selectAll : clearSelected}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
      ),
    },
    {
      key: "username",
      name: "User" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"username"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "invoicenumber",
      name: "Invoice Number" + postValue,
      width: cellWidthSmall,
      filterable: true,
      summaryFormatter: SummaryTotal,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.InvoiceNumber} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"invoicenumber"} data={data} section="consolidationselected" />;
      },
    },
    showTotals && {
      key: "ident",
      name: "Ident" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (row.camend_status == 4 ? <div>{row[column.key]}</div> : <></>),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ident"} data={data} section="consolidationselected" />;
      },
    },
    showTotals && {
      key: "camend_final",
      name: "Paid" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (row.camend_status == 4 ? <div>{row[column.key]}</div> : <></>),
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"camend_final"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "barcode",
      name: "Barcode" + postValue,
      width: cellWidthLarge,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.Barcode} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"barcode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "grossWeight",
      name: "Gross W" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"grossWeight"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "weight",
      name: "Weight" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"weight"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "arrivaldetail_qualityscore",
      name: "Quality" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return (
          <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"arrivaldetail_qualityscore"} data={data} section="consolidationselected" />
        );
      },
    },
    {
      key: "orig_intake_waybill",
      name: "Orig Intake Waybill" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orig_intake_waybill"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "IntakeWaybill",
      name: "Intake Waybill" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => <EnquiryLink {...props} selector={Selector.IntakeWaybill} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"IntakeWaybill"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "wk_weekcold",
      name: "Cold Week" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"wk_weekcold"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "producerid",
      name: "Producer ID" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerid"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "FarmNumber",
      name: "Farm Number" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"FarmNumber"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "prod_payment",
      name: "Prod PMT TYPE" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"prod_payment"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "prod_dealtype",
      name: "Prod DEAL TYPE" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"prod_dealtype"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "consignee",
      name: "Client" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "clients_dealtype",
      name: "Selling Terms" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"clients_dealtype"} data={data} section="consolidationselected" />;
      },
      formatter: ({ row, column }) => <div>{DealType[row[column.key]] || ""}</div>,
    },
    {
      key: "sales_week",
      name: "Sales Week" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sales_week"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "vessel_code",
      name: "Vessel" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "containerno",
      name: "Container" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "portdist_code",
      name: "POD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portdist_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "portfinal_code",
      name: "Dest" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"portfinal_code"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "ata",
      name: "ATA" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ata"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "TargetMarket",
      name: "Target Market" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"TargetMarket"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "Target Country",
      name: "Target Country" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"Target Country"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "target_shipping",
      name: "Shipped Market" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"target_shipping"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "shipped_country",
      name: "Shipped Country" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shipped_country"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "VarietyCode",
      name: "Variety Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"VarietyCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "GradeCode",
      name: "Grade Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"GradeCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "CountCode",
      name: "Count Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"CountCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "PackCode",
      name: "Pack Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"PackCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "MarkCode",
      name: "Mark Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"MarkCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "InventoryCode",
      name: "Inventory Code" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"InventoryCode"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="consolidationselected" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "NoCartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"NoCartons"} data={data} section="consolidationselected" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "currency",
      name: "Currency" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"currency"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "exchangerate",
      name: "ROE" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"exchangerate"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "sellingprice",
      name: "Selling Price" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcsellingprice"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sale_credit",
      name: "Credit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"sale_credit_total"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_credit"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sale_debit",
      name: "Debit" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"sale_debit_total"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_debit"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sellingprice_final",
      name: "Selling Price Final" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcsellingprice_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sellingprice_final"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "foreigncost",
      name: "Foreign Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.foreigncost) != 0) {
          return (
            <span
              title="Info"
              onClick={() => {
                handleForeignCostInfo(row);
              }}
            >
              <IconInfo style={{ color: "blue", float: "left" }} />
              <FormatterNumber2Decimals {...props} />
            </span>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcforeigncost"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"foreigncost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "nettsellprice",
      name: "FOB Sell Price" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcnettsellprice"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nettsellprice"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "adj_fob_sellprice",
      name: "ADJ FOB Sell Price" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcadj_fob_sellprice"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adj_fob_sellprice"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "ld_fob",
      name: "PI FOB" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          return (
            <span
              title="Info"
              onClick={() => {
                handlePIInfoClick(row);
              }}
            >
              <IconInfo style={{ color: "blue", float: "left" }} />
              <FormatterNumber2Decimals {...props} />
            </span>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_fob"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "fob_sellprice_zar",
      name: "FOB Sell Price ZAR" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcfob_sellprice_zar"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fob_sellprice_zar"} data={data} section="consolidationselected" />;
      },
    },
    // {
    //   key: "roe_etd",
    //   name: "ROE ETD" + postValue,
    //   width: cellWidthSmall,
    //   filterable: true,
    //   formatter: FormatterNumber2Decimals,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_etd"} data={data} section="consolidationselected" />;
    //   },
    // },
    // {
    //   key: "roe_eta",
    //   name: "ROE ETA" + postValue,
    //   width: cellWidthSmall,
    //   filterable: true,
    //   formatter: FormatterNumber2Decimals,
    //   filterRenderer: (e) => {
    //     return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"roe_eta"} data={data} section="consolidationselected" />;
    //   },
    // },
    {
      key: "adj_fob_sellprice_zar",
      name: "ADJ FOB SELL PRICE ZAR" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcadj_fob_sellprice_zar"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adj_fob_sellprice_zar"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "fobcost",
      name: "Fob Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcfobcost"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "fobcost_est",
      name: "Fob Cost Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcfobcost_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "fobcost_final",
      name: "Fob Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcfobcost_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"fobcost_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "coldcost",
      name: "Cold Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalccoldcost"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "coldcost_est",
      name: "Cold Cost Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalccoldcost_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "coldcost_final",
      name: "Cold Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalccoldcost_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"coldcost_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "ppecb_est",
      name: "PPECB Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcppecb_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ppecb_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "ppecb_final",
      name: "PPECB Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcppecb_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ppecb_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "cga_est",
      name: "CGA Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalccga_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cga_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "cga_final",
      name: "CGA Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalccga_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"cga_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "sati_est",
      name: "SATI Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcsati_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sati_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "sati_final",
      name: "SATI Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcsati_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sati_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "transportCost",
      name: "TRANSPORT Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalctransportCost"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"transportCost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "transportCost_est",
      name: "TRANSPORT Cost Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalctransportCost_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"transportCost_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "transportCost_final",
      name: "TRANSPORT Cost Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalctransportCost_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"transportCost_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "royaltiesCost_est",
      name: "ROYALTIES Est" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ({ row, column }) => (
        <ContextMenuContainer row={row} column={column}>
          <FormatterNumber2Decimals column={column} row={row} />
        </ContextMenuContainer>
      ),
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcroyaltiesCost_est"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"royaltiesCost_est"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "royaltiesCost_final",
      name: "ROYALTIES Used" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcroyaltiesCost_final"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"royaltiesCost_final"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "margin",
      name: "Margin" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcmargin"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"margin"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "marginperc",
      name: "Margin %" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"marginperc"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "totalcost",
      name: "Total Local Cost" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalctotalcost"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcost"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "totalcostinclmargin",
      name: "Total Local Cost Incl Margin" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalctotalcostinclmargin"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"totalcostinclmargin"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "rtgc",
      name: "RTGC" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcrtgc"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgc"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "rtgczar",
      name: "RTGC ZAR" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcrtgczar"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgczar"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "ld_dip",
      name: "PI DIP" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        if (Number(row.ld_fob) > 0) {
          return (
            <span
              title="Info"
              onClick={() => {
                handlePIInfoClick(row);
              }}
            >
              <IconInfo style={{ color: "blue", float: "left" }} />
              <FormatterNumber2Decimals {...props} />
            </span>
          );
        }
        return <FormatterNumber2Decimals {...props} />;
      },
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ld_dip"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "diff_rtg_dip",
      name: "DIFF RTG VS DIP" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"diff_rtg_dip"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "adjustment",
      name: "PROD ADJ" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: (props) => {
        const { row } = props;
        return <FormatterNumber2Decimals {...props} style={{ color: row.camend_status < Status.Processed ? "green" : "black" }} />;
      },
      summaryFormatter: (data) => <SummaryNumber2DecimalFieldSpecify data={data} field={"totalcalcadjustment"} />,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"adjustment"} data={data} section="consolidationselected" />;
      },
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: (row) => row.camend_status < Status.Processed && canEdit,
    },
    {
      key: "comment",
      name: "Comment" + postValue,
      width: cellWidthSmall,
      filterable: false,
      formatter: ({ column, row }) => {
        return <div style={{ color: row.camend_status < Status.Processed ? "green" : "black" }}>{row[column.key]}</div>;
      },
      editor: (props) => <TextEditor {...props} maxTextLength={255} />,
      editable: (row) => [Status.Unapproved, Status.Pending].includes(row.camend_status) && canEdit,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"comment"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "advance",
      name: "PROD ADV" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"advance"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "final",
      name: "PROD FIN" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"final"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "rtgp",
      name: "RTGP" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgp"} data={data} section="consolidationselected" />;
      },
    },
    {
      key: "rtgpzar",
      name: "RTGP ZAR" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      summaryFormatter: SummaryNumber2Decimal,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"rtgpzar"} data={data} section="consolidationselected" />;
      },
    },
  ]
    .filter((item) => item)
    .map((c) => ({ ...c, ...defaultColumnProperties }));

  if (!handleCheckboxSelect) {
    columns.splice(0, 1);
  }

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

export function getCellActions(column, row) {
  return null;
}
