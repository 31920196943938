import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconLock from "@material-ui/icons/Lock";
import Tooltip from "@material-ui/core/Tooltip";

import { FormatterNumber, FormatterNumber2Decimals, FormatterTypes, FormatterArrivalQuality } from "../../lib/components/Formatters";
import TextEditor from "../../lib/components/TextEditor";
import { SummaryNumber } from "../../lib/components/SummaryFormatter";

const cellWidthSuperTiny = 50;
const cellWidthTiny = 75;
const cellWidthSmall = 110;
const cellWidthSmallBigger = 120;
const cellWidthMedium = 200;
const cellWidthMediumSmaller = 180;
const cellWidthLarge = 300;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

let currentSpace = true;
export const GridColumns = (data, filters) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  return [
    {
      key: "farmnumber",
      name: "Farm" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farmnumber"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "orchard",
      name: "Orchard" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orchard"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "pickingref",
      name: "Pick Ref" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pickingref"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "waybill",
      name: "Waybill" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"waybill"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "intakepalletid",
      name: "Intake Pallet ID" + postValue,
      width: cellWidthMediumSmaller,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"intakepalletid"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "varietycode",
      name: "Variety" + postValue,
      width: cellWidthSuperTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varietycode"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "gradecode",
      name: "Grade" + postValue,
      width: cellWidthSuperTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"gradecode"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "countcode",
      name: "Count" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"countcode"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "markcode",
      name: "Brand" + postValue,
      width: cellWidthSuperTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"markcode"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
    },
    {
      key: "nocartons",
      name: "Cartons" + postValue,
      width: cellWidthSuperTiny,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nocartons"} data={data} section={"arrivalsdetailgridsetup"} />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "qualityscore",
      name: "Quality" + postValue,
      filterable: true,
      sortable: true,
      resizable: true,
      width: cellWidthTiny,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"qualityscore"} data={data} section="arrivalsdetailgridsetup" />;
      },
      formatter: FormatterArrivalQuality,
      editor: (props) => <TextEditor {...props} align="right" />,
      editable: true,
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));
};

// const cartonCountFormatter = (event, allRows) => {
//   if (allRows && allRows.length > 0) {
//     const totalled = allRows.filter((rows) => rows.intakepalletid === event.row.intakepalletid).reduce((a, b) => a + (b["nocartons"] || 0), 0);
//     return (
//       <Tooltip title={totalled}>
//         <div>{event.row.nocartons}</div>
//       </Tooltip>
//     );
//   }
//   return <div></div>;
// };

const actions = (row) => {
  return [
    {
      icon: (
        <span title="Remove">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {},
    },
    {
      icon: (
        <span title="Complete">
          <IconLock style={{ cursor: "pointer", color: "blue" }} />
        </span>
      ),
      callback: (e) => {},
    },
    {
      icon: (
        <span title="Edit">
          <IconEdit style={{ cursor: "pointer", color: "orange" }} />
        </span>
      ),
      callback: (e) => {},
    },
  ];
};

export default function getCellActions(column: any, row: any) {
  if (column.key != "actions") {
    return undefined;
  }
  const cellActions = {
    actions: actions(row),
  };
  return cellActions[column.key];
}

// class qualityScoreEditor extends React.Component<any, any> {
//   state = {
//     qualityscore: 0.0,
//     accepted: undefined,
//     targetElementID: undefined,
//     match: undefined,
//   };

//   constructor(props) {
//     super(props);
//     this.state.qualityscore = props.value;
//     this.state.accepted = props.rowData.accepted;
//     this.state.match = props.rowData.match;
//     this.state.targetElementID = `qualityscore_${props.rowData.intakepalletid}`;
//   }

//   getValue() {
//     return { qualityscore: this.state.qualityscore };
//   }

//   getInputNode() {
//     return document.getElementById(this.state.targetElementID);
//   }

//   handleChangeComplete = (e) => {
//     if (Number(e.target.value) > 4) {
//       e.target.value = 4;
//     }
//     if (Number(e.target.value) < 1) {
//       e.target.value = 1;
//     }
//     this.setState({ qualityscore: e.target.value }, () => {});
//   };

//   handleBlur = (e) => {
//     if (Number(this.state.qualityscore) > 4) {
//       this.setState({ qualityscore: 4 }, () => {
//         this.props.onCommit();
//       });
//     } else if (Number(this.state.qualityscore) < 1) {
//       this.setState({ qualityscore: 1 }, () => {
//         this.props.onCommit();
//       });
//     } else {
//       this.props.onCommit();
//     }
//   };

//   render() {
//     return <input id={this.state.targetElementID} value={this.state.qualityscore} onChange={this.handleChangeComplete} onBlur={this.handleBlur} />;
//   }
// }

// const InputFormatter = (data) => {
//   const qualityscore = data.row.qualityscore;
//   const backgroundColorSwitch = qualityscore == 1 ? "red" : qualityscore == 2 ? "orange" : qualityscore == 3 ? "green" : "white";

//   return (
//     <div
//       style={{
//         textAlign: "center",
//         alignContent: "right",
//         border: "1px solid black",
//         paddingTop: "2",
//         paddingLeft: "8px",
//         paddingRight: "8px",
//         marginTop: "-1px",
//         backgroundColor: backgroundColorSwitch,
//         fontWeight: "bold",
//       }}
//     >
//       {data.value}
//     </div>
//   );
// };
