import React from "react";
import CustomAutoCompleteFilter from "../../lib/components/customdatagridfilter/CustomFilter";

import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconArchive from "@material-ui/icons/Archive";
import IconBrightness from "@material-ui/icons/Brightness1";
import Tooltip from "@material-ui/core/Tooltip";

import { format } from "date-fns";
import parseISO from "date-fns/parseISO";

import { handlePrint } from "../../reports/arrivalPDF";
import { ArrivalStatus } from "../../lib/types";
import { CellActionsFormatter } from "../../lib/helpers/CellActionsFormatter";
import { DateFormatter, FormatterBoolean, FormatterNumber, FormatterNumber2Decimals } from "../../lib/components/Formatters";
import { handleColumnsArrangement, handleColumnsWidth } from "../../lib/helpers/grid";
import { DocumentsType } from "../../lib/components/DocumentsButton";
import { SummaryNumber, SummaryNumber2Decimal } from "../../lib/components/SummaryFormatter";

const cellWidthTiny = 50;
const cellWidthSmall = 100;
const cellWidthSmallBigger = 120;
const cellWidthMedium = 200;
const cellWidthLarge = 300;

const defaultColumnProperties = {
  resizable: true,
  sortable: true,
};

const etaFormatter = (data) => {
  if (data.row.eta) {
    const filterDate = format(parseISO(data.row.eta), "dd MMM yyyy");
    return <div>{filterDate}</div>;
  }
  return <div></div>;
};

const ataFormatter = (data) => {
  if (data.row.ata && format(parseISO(data.row.ata), "yyyy") != "1900") {
    const filterDate = format(parseISO(data.row.ata), "dd MMM yyyy");
    return <div>{filterDate}</div>;
  }
  return <div></div>;
};

const StatusFormatter = ({ row, column }) => {
  const data = row[column.key];
  if (data == ArrivalStatus.New) {
    return (
      <div>
        <Tooltip title={ArrivalStatus.New}>
          <IconBrightness style={{ color: "white", display: "block", margin: "auto", borderColor: "gray", borderStyle: "solid", borderRadius: "100px", borderWidth: "thin" }} />
        </Tooltip>
      </div>
    );
  }
  if (data == ArrivalStatus.InProgress) {
    return (
      <div>
        <Tooltip title={ArrivalStatus.InProgress}>
          <IconBrightness style={{ color: "orange", display: "block", margin: "auto" }} />
        </Tooltip>
      </div>
    );
  }
  if (data == ArrivalStatus.Complete) {
    return (
      <div>
        <Tooltip title={ArrivalStatus.Complete}>
          <IconBrightness style={{ color: "green", display: "block", margin: "auto" }} />
        </Tooltip>
      </div>
    );
  }
};

const ClaimFormatter = (data) => {
  if (data.row.claim) {
    return (
      <div>
        <IconBrightness style={{ color: "red", display: "block", margin: "auto" }} />
      </div>
    );
  } else {
    return (
      <div>
        <IconBrightness style={{ color: "green", display: "block", margin: "auto" }} />
      </div>
    );
  }
};

let currentSpace = true;
export const GridColumns = (data, filters, arrangement = undefined, columnsWidth, handleEdit, handleRemove, loading) => {
  let postValue = "";

  if (data) {
    if (currentSpace) {
      postValue = " ";
    }
    currentSpace = !currentSpace;
  }

  const columns = [
    {
      key: "actions",
      name: "Actions",
      width: cellWidthSmallBigger,
      formatter: ({ row }) => {
        const actions = cellActions(row, handleEdit, handleRemove);
        return (
          <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
            <CellActionsFormatter actions={actions} disabled={loading} />
          </div>
        );
      },
    },
    {
      key: "status_description",
      name: "#" + postValue,
      width: cellWidthTiny,
      filterable: true,
      formatter: StatusFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"status_description"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "claim",
      name: "Claim" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: ClaimFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"claim"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "average",
      name: "Ave Qlty Score" + postValue,
      width: cellWidthTiny,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"average"} data={data} section="arrivalsgridsetup" />;
      },
      formatter: FormatterNumber,
    },
    {
      key: "salesref",
      name: "Sales Ref" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"salesref"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "consigneeid",
      name: "Consignee" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consigneeid"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "consignee",
      name: "Consignee Name" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"consignee"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "pod",
      name: "POD" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pod"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "poddesc",
      name: "POD Desc" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"poddesc"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "eta",
      name: "ETA",
      width: cellWidthSmallBigger,
      formatter: etaFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"eta"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "updated_eta",
      name: "Updated ETA",
      width: cellWidthSmallBigger,
      formatter: DateFormatter,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"updated_eta"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "ata",
      name: "ATA",
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"ata"} data={data} section="arrivalsgridsetup" />;
      },
      formatter: ataFormatter,
    },
    {
      key: "producerid",
      name: "Producer ID" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producerid"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "producer",
      name: "Producer" + postValue,
      width: cellWidthSmallBigger,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"producer"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "farmnumber",
      name: "Farm" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"farmnumber"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "varieties",
      name: "Variety" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"varieties"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "orchard",
      name: "Orchard" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"orchard"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "pickingref",
      name: "Picking Ref" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"pickingref"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "nocartons",
      name: "No Cartons" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"nocartons"} data={data} section="arrivalsgridsetup" />;
      },
      summaryFormatter: SummaryNumber,
    },
    {
      key: "palletSize",
      name: "Pallet Size" + postValue,
      width: cellWidthSmall,
      filterable: true,
      formatter: FormatterNumber2Decimals,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"palletSize"} data={data} section="arrivalsgridsetup" />;
      },
      summaryFormatter: SummaryNumber2Decimal,
    },
    {
      key: "shippingCode",
      name: "Ship Line" + postValue,
      width: cellWidthSmall,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"shippingCode"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "vessel",
      name: "Vessel" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"vessel"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "containerno",
      name: "Container No" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"containerno"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "sale_arrived",
      name: "Arrived" + postValue,
      width: cellWidthTiny,
      filterable: true,
      formatter: FormatterBoolean,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_arrived"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "sale_collected",
      name: "Collected" + postValue,
      width: cellWidthTiny,
      filterable: true,
      formatter: FormatterBoolean,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"sale_collected"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "note",
      name: "Note" + postValue,
      width: cellWidthMedium,
      filterable: true,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"note"} data={data} section="arrivalsgridsetup" />;
      },
    },
    {
      key: "arrival_updated_at",
      name: "Updated" + postValue,
      width: cellWidthMedium,
      filterable: true,
      formatter: DateFormatter,
      filterRenderer: (e) => {
        return <CustomAutoCompleteFilter {...e} multiSelection={true} currentFilters={filters} fieldname={"arrival_updated_at"} data={data} section="arrivalsgridsetup" />;
      },
    },
  ].map((c) => ({ ...c, ...defaultColumnProperties }));

  let columnsArranged = [];
  columnsArranged = handleColumnsArrangement(columnsArranged, arrangement, columns);
  columnsArranged = handleColumnsWidth(columnsArranged, columnsWidth, columns);

  return columnsArranged;
};

const cellActions = (row, handleEdit, handleRemove) => {
  const actionsArr = [];
  actionsArr.push({
    icon: (
      <span title="Edit">
        <IconEdit style={{ cursor: "pointer", color: "orange" }} />
      </span>
    ),
    callback: (e) => {
      handleEdit(row.containerno, row.producerid, row.salesref, row.arrival_id, row.status, row.claim);
    },
  });
  if (row.arrival_id) {
    actionsArr.push({
      icon: (
        <span title="Documents">
          <IconArchive style={{ cursor: "pointer", color: "#4a9de2" }} />
        </span>
      ),
      actions: [
        {
          icon: DocumentsType.PDF,
          text: "Arrival Quality Report",
          callback: () => {
            handlePrint(row.arrival_id, row.containerno, row.producerid, row.salesref);
          },
        },
        {
          icon: DocumentsType.EXCEL,
          text: "Arrival Quality Report",
          callback: () => {
            window.location.href = `/api/arrival/ext/exportArrival/${row.arrival_id}/${row.container ? row.container : "0"}/${row.producerid}/${row.salesref}`;
          },
        },
      ],
    });
    actionsArr.push({
      icon: (
        <span title="Remove">
          <IconDelete style={{ cursor: "pointer", color: "red" }} />
        </span>
      ),
      callback: (e) => {
        handleRemove(row.arrival_id);
      },
    });
  }

  return actionsArr.reverse();
};

export const getDetailedExportHeaders = () => {
  return [
    { key: "status" },
    { key: "salesref" },
    { key: "consigneeid" },
    { key: "consignee" },
    { key: "pod" },
    { key: "eta" },
    { key: "ata" },
    { key: "producerid" },
    { key: "consignmentref" },
    { key: "farmnumber" },
    { key: "vessel" },
    { key: "containernumber" },
    { key: "created_at" },
    { key: "salesref" },
    { key: "intakepalletid" },
    { key: "qualityscore" },
    { key: "varietycode" },
    { key: "gradecode" },
    { key: "countcode" },
    { key: "brand" },
    { key: "pickingref" },
  ];
};
